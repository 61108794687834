import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ComponentNavbar from '../../../application/components/componentNavbar';
import DeleteConfirmationModal from '../../../application/components/deleteConfirmationModal';
import Pagination from '../../../application/components/pagination';
import SingleDetail from '../../../application/components/singleDetail';
import Autocomplete from "../../../application/containers/autocomplete.container";
import Acl from '../../../services/acl.service';
import DictManager from "../../../services/dict.service";
import WarehousePositionsComponent from '../warehousePositions/warehousePositions.component';
import Chart from "../../../application/components/chart.component";

export default class WarehouseDetailsComponent extends Component {

    state = {
        modalVisibility: false,
        positionId: null,
        page: 1,
        activeTab: "positions-tab"
    }

    componentDidMount() {
        const { match, getWarehouse } = this.props;
        let warehouseId = _.get(match, 'params.id', null);
        if (warehouseId) {
            getWarehouse(warehouseId, true);
        }
    }

    renderDetails = () => {
        const { warehouse, auth } = this.props;
        if (warehouse) return (
            <div className="details mb-5">
                <div className="single-detail">
                    <SingleDetail
                        label='Nazwa magazynu'
                        value={warehouse.name}
                    />
                </div>
                <div className="single-detail">
                    <SingleDetail
                        label='Kod magazynu'
                        value={warehouse.code}
                    />
                </div>
                <div className="single-detail-button">
                    <Link to={{
                        pathname: "/warehouses/position",
                        state: { warehouseCode: warehouse.code, warehouseId: warehouse._id }
                    }} >
                        {Acl.isAllowedAction('warehouse', 'position', 'create', auth.isAdmin) ? <button
                            type="button"
                            className="btn btn-outline-primary">Dodaj</button> : null}
                    </Link>
                </div>
            </div>
        )
    }

    handleCloseModal = () => {
        this.setState({
            modalVisibility: false
        })
    }

    handleOpenDeleteModal = (positionId) => {
        this.setState({
            modalVisibility: true, positionId: positionId
        });
    }


    handleOnDeletePosition = () => {
        const { deleteWarehousePosition } = this.props;
        const { positionId } = this.state;
        deleteWarehousePosition(positionId);
        this.setState({ modalVisibility: false });
    }

    setPage = (page) => {
        this.setState({ page: page });
    }

    getPositions = () => {
        const { warehouse, getWarehousePositions } = this.props;
        getWarehousePositions(warehouse.code);
    }

    navigationButtons = () => {
        const { positionsCount, offset, limit, positions, setOffset } = this.props;
        const { page } = this.state;

        if (positions && positions.length > 0) return (
            <Pagination
                getDocuments={this.getPositions}
                changeOffset={setOffset}
                offset={offset}
                total={positionsCount}
                perPage={limit}
                setPage={this.setPage}
                page={page}
            />
        )
    }

    handlePositionDetailsClick = (positionId) => {
        const { history } = this.props;
        history.push(`/warehouses/position/${positionId}`);
    }

    changeFilter = (e, name) => {
        const { setFilters, getWarehousePositions, warehouse } = this.props;
        let value = e.target.value;
        if (name === "type" && value === "0") {
            value = null;
        }
        setFilters(name, value);
        this.setState({ page: 1 });
        getWarehousePositions(warehouse.code);
    }

    changeAutocompleteFilter = (name, value) => {
        const { setFilters, getWarehousePositions, warehouse } = this.props;
        this.setState({ page: 1 });
        setFilters(name, value);
        getWarehousePositions(warehouse.code);
    }


    resetFilters = () => {
        const { resetFilters, getWarehousePositions, warehouse } = this.props;
        resetFilters();
        this.setState({ page: 1 });
        getWarehousePositions(warehouse.code);
    }

    renderSearchBar = () => {
        const { filters } = this.props;
        let options = DictManager.getOptionsByType(1);

        return (
            <div>
                <div className="row">
                    <div className="col-md-3 offset-6">
                        {filters.product && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("product", null)}>
                                    {filters.product.name}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label>Wyszukaj pozycję</label>
                        <input type="text" className="form-control" placeholder="Wpisz kod pozycji" value={filters.query} onChange={(e) => this.changeFilter(e, 'query')} />
                    </div>
                    <div className="col-md-3">
                        <label>Typ pozycji</label>
                        <select className="form-control" value={filters.type || "Wybierz"} onChange={(e) => this.changeFilter(e, 'type')}>
                            {options}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label>Produkt</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("product", value)} content="product" name="productSearch" />
                    </div>
                    <div className="col-md-2 text-right">
                        <button className="btn btn-secondary mt-4" onClick={() => this.resetFilters()}>Usuń filtry</button>
                    </div>
                </div>
            </div>);
    }

    renderDeleteModal = () => {
        const { modalVisibility } = this.state;

        if (!modalVisibility) {
            return null;
        }
        return <DeleteConfirmationModal
            title="Usuwanie pozycji"
            msg="Czy na pewno chcesz usunąć tą pozycję?"
            handleCloseModal={this.handleCloseModal}
            confirmAction={this.handleOnDeletePosition}
        />;
    }

    setActiveTab = (name) => {
        const { activeTab } = this.state;
        if (name !== activeTab) {
            document.getElementById(activeTab).classList.remove("active");
            document.getElementById(name).classList.add("active");
            this.setState({ activeTab: name });

        }
    }

    getDetailsTabs = () => {
        const { isAdmin } = this.props;
        let hasStatisticsPermission = Acl.isAllowedAction("warehouse", "warehouse", "getWarehouseStatistics", isAdmin);

        return (
            <ul className="nav nav-tabs user-select-none">
                <li className="nav-item">
                    <span className="nav-link active" id="positions-tab" onClick={() => this.setPositionTab()}>Pozycje</span>
                </li>
                {hasStatisticsPermission && <li className="nav-item">
                    <span className="nav-link" id="statistics-tab" onClick={() => this.getStatistics()}>Statystyki</span>
                </li>}
            </ul>
        )
    }

    getTabContent = () => {
        const { activeTab } = this.state;
        const positions = this.renderWarehousePositions();
        const statistics = this.renderWarehouseStatistics();

        if (activeTab === "positions-tab") {
            return positions;
        } else if (activeTab === "statistics-tab") {
            return statistics;
        } else {
            return null;
        }
    }

    setPositionTab = () => {
        this.getPositions();
        this.setActiveTab("positions-tab");
    }


    renderWarehousePositions = () => {
        const { positions, isAdmin } = this.props;
        const searchBar = this.renderSearchBar();
        const pagination = this.navigationButtons();

        return (
            <div className="mt-5">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="text-center">Lista pozycji magazynowych</h3>
                    </div>
                </div>
                {searchBar}
                {pagination}
                <WarehousePositionsComponent
                    positions={positions || []}
                    onDeletePosition={(positionId) => this.handleOpenDeleteModal(positionId)}
                    positionsFieldsLength={this.props.positionsFieldsLength}
                    positionDetailsClick={(positionId) => this.handlePositionDetailsClick(positionId)}
                    isAdmin={isAdmin} />
                {pagination}
            </div>
        );
    }

    getStatistics = () => {
        const { getWarehouseStatistics } = this.props;
        getWarehouseStatistics();
        this.setActiveTab("statistics-tab");
    }

    renderWarehouseStatistics = () => {
        const { statistics } = this.props;

        if (!statistics) {
            return null;
        }

        return (<div className="mt-5">
            <div className="row">
                <div className="col-md-12">
                    <h3 className="text-center">Statystyki magazynu</h3>
                </div>
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-6">
                    <Chart type="pie" name="usage" data={statistics.usageStats} title="Wykorzystanie pozycji magazynowych" />
                </div>
                <div className="col-6">
                    <Chart type="pie" name="types" data={statistics.typesStats} title="Podział pozycji magazynowych" />
                </div>
                <div className="col-12 mt-2">
                    <Chart type="bar" name="completion" data={statistics.completionStats} title="Ilość zrealizowanych dokumentów w danym dniu" />
                </div>
            </div>
        </div>);
    }

    render() {
        const deleteModal = this.renderDeleteModal();
        const warehouseDetails = this.renderDetails();
        const tabs = this.getDetailsTabs();
        const tabsContent = this.getTabContent();

        return (
            <div className="container">
                {deleteModal}
                <ComponentNavbar
                    title="Szczegóły magazynu"
                />
                {warehouseDetails}
                {tabs}
                {tabsContent}
            </div>
        )

    }

    componentWillUnmount() {
        const { clearWarehouse, resetFilters, clearWarehouseStatistics } = this.props;
        clearWarehouse();
        resetFilters();
        clearWarehouseStatistics();
    }

}
import React from 'react';
import { connect } from 'react-redux';
import { getInventory, clearInventory, deleteInventory, closeInventory, resetInventoryPosition, generateReport, allowInventoryEdit, setHistoryFilters, setHistoryOffset, resetHistoryFilters, getInventoryHistory, clearInventoryHistory } from '../actions/inventory.action'
import InventoryDetails from '../components/inventoryDetails.component';

class InventoryDetailsContainer extends React.Component {
    render() {
        return (
            <div>
                <InventoryDetails {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        inventory: store.inventory.inventory,
        history: store.inventory.history,
        isAdmin: store.auth.isAdmin
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getInventory: (id) => {
            dispatch(getInventory(id));
        },
        clearInventory: () => {
            dispatch(clearInventory());
        },
        deleteInventory: () => {
            dispatch(deleteInventory());
        },
        closeInventory: () => {
            dispatch(closeInventory());
        },
        resetInventoryPosition: (id, index) => {
            dispatch(resetInventoryPosition(id, index));
        },
        generateReport: () => {
            dispatch(generateReport());
        },
        allowInventoryEdit: (positionCode, index) => {
            dispatch(allowInventoryEdit(positionCode, index));
        },
        getInventoryHistory: () => {
            dispatch(getInventoryHistory());
        },
        clearInventoryHistory: () => {
            dispatch(clearInventoryHistory());
        },
        setHistoryOffset: (offset) => {
            dispatch(setHistoryOffset(offset));
        },
        setHistoryFilters: (name, value) => {
            dispatch(setHistoryFilters(name, value));
        },
        resetHistoryFilters: () => {
            dispatch(resetHistoryFilters());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDetailsContainer);
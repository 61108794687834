import React from 'react';
import { connect } from 'react-redux';
import { clearWarehouse, deleteWarehousePosition, getWarehouse, setOffset, getWarehousePositions, setFilters, resetFilters, getWarehouseStatistics, clearWarehouseStatistics } from '../actions/warehouse.action';
import WarehouseDetailsComponent from '../components/warehouse/warehouseDetails.component';

class WarehouseDetailsContainer extends React.Component {

    render() {
        return (
            <div>
                <WarehouseDetailsComponent {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        warehouse: store.warehouse.warehouse,
        filters: store.warehouse.filters,
        limit: store.warehouse.limit,
        offset: store.warehouse.offset,
        positions: store.warehouse.positions,
        positionsCount: store.warehouse.positionsCount,
        isAdmin: store.auth.isAdmin,
        statistics: store.warehouse.statistics
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getWarehouse: (warehouseId) => {
            dispatch(getWarehouse(warehouseId));
        },
        deleteWarehousePosition: (id) => {
            dispatch(deleteWarehousePosition(id));
        },
        clearWarehouse: () => {
            dispatch(clearWarehouse());
        },
        setOffset: (offset) => {
            dispatch(setOffset(offset));
        },
        getWarehousePositions: (warehouseCode) => {
            dispatch(getWarehousePositions(warehouseCode));
        },
        setFilters: (filter, value) => {
            dispatch(setFilters(filter, value))
        },
        resetFilters: () => {
            dispatch(resetFilters());
        },
        getWarehouseStatistics: () => {
            dispatch(getWarehouseStatistics())
        },
        clearWarehouseStatistics: () => {
            dispatch(clearWarehouseStatistics())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseDetailsContainer);
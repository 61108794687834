import _ from 'lodash';
import { toast } from 'react-toastify';
import { isLoading } from '../../application/actions/application.actions';
import Acl from '../../services/acl.service';
import Api from '../../services/api';

const namespace = 'PRODUCT';
export const SET_PRODUCT = `SET_PRODUCT_${namespace}`;
export const CLEAR_PRODUCT = `CLEAR_PRODUCT_${namespace}`;
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${namespace}`;
export const SET_PRODUCT_HISTORY = `SET_PRODUCT_HISTORY_${namespace}`;
export const CLEAR_PRODUCT_HISTORY = `CLEAR_PRODUCT_HISTORY_${namespace}`;
export const SET_HISTORY_OFFSET = `SET_HISTORY_OFFSET_${namespace}`;
export const SET_HISTORY_FILTERS = `SET_HISTORY_FILTERS_${namespace}`;
export const RESET_HISTORY_FILTERS = `RESET_HISTORY_FILTERS_${namespace}`;
export const SET_PRODUCT_RESERVATIONS = `SET_PRODUCT_RESERVATIONS_${namespace}`;
export const CLEAR_PRODUCT_RESERVATIONS = `CLEAR_PRODUCT_RESERVATIONS_${namespace}`;

export const setProduct = (product) => dispatch => {
    dispatch({
        type: SET_PRODUCT,
        product
    });
}

export const clearProduct = () => dispatch => {
    dispatch({
        type: CLEAR_PRODUCT
    })
}

export const setErrors = (err) => dispatch => {
    dispatch({
        type: SET_ERRORS,
        err
    })
}

export const clearErrors = () => dispatch => {
    dispatch({
        type: CLEAR_ERRORS
    })
}

export const getProduct = (id) => async (dispatch, getState) => {
    try {
        const { auth: { isAdmin } } = getState();

        dispatch(isLoading(true));
        dispatch(clearErrors());

        const hasProductPositionsPermission = Acl.isAllowedAction('product', 'product', 'getProductPositions', isAdmin);

        let res = await Api.get(`/product/product/${id}`);

        if (res && res.success) {
            let product = res.document;

            if ('categories' in product) {
                product.categories = product.categories.map((cat) => {
                    return {
                        _id: cat.categoryId,
                        name: cat.name,
                        ancestors: cat.ancestors,
                        breadcrumb: cat.breadcrumb
                    }
                });
            }

            if (hasProductPositionsPermission) {
                let res2 = await Api.get(`/product/product/getProductPositions/${id}`);

                dispatch(isLoading(false));

                if (res2 && res2.success) {
                    product.positions = res2.documents;
                }
                dispatch(setProduct(product));
            } else {
                dispatch(isLoading(false));
                dispatch(setProduct(product));
            }
        } else {
            dispatch(isLoading(false));
            toast("Wystąpił błąd");
        }
    } catch (err) {
        toast("Wystąpił błąd");
        dispatch(isLoading(false));
    }
}

export const editProduct = (onSuccess) => async (dispatch, getState) => {
    try {
        const { product: { product } } = getState();
        dispatch(isLoading(true));
        dispatch(clearErrors());

        let data = JSON.parse(JSON.stringify(product));

        let res = await Api.put(`/product/product/${product._id}`, data);

        dispatch(isLoading(false));

        if (res && res.success) {
            toast("Zapisano zmiany");
            onSuccess();
        } else {
            if (res.err) {
                dispatch(setErrors(res.err));
            }
            toast("Wystąpił błąd");
        }
    } catch (err) {
        toast("Wystąpił błąd");
        dispatch(isLoading(false));
    }
}

export const addProduct = (onSuccess) => async (dispatch, getState) => {
    try {
        const { product: { product } } = getState();
        dispatch(isLoading(true));
        dispatch(clearErrors());

        let data = {};

        _.each(product, (value, key) => {
            if (!_.isString(value) || (_.isString(value) && !_.isEmpty(value))) {
                data[key] = value;
            }
        });

        if (!data.PAO) {
            data.PAO = false;
        }
        if (!data.gratis) {
            data.gratis = false;
        }

        let res = await Api.post(`/product/product`, data);

        dispatch(isLoading(false));

        if (res && res.success) {
            toast("Dodano produkt")
            onSuccess();
        } else {
            if (res.err) {
                dispatch(setErrors(res.err));
            }
            toast("Wystąpił błąd");
        }
    } catch (err) {
        toast("Wystąpił błąd");
        dispatch(isLoading(false));
    }
}

export const deleteProduct = (id, history) => async (dispatch) => {
    try {
        dispatch(isLoading(true));

        let res = await Api.delete(`/product/product/${id}`);

        dispatch(isLoading(false));

        if (res && res.success) {
            toast("Usunięto produkt");
            history.push("/products");
        } else {
            toast("Wystąpił błąd");
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast("Wystąpił błąd");
    }
}

export const getProductHistory = () => async (dispatch, getState) => {
    try {
        const { product: { history: { filters, limit, offset }, product } } = getState();

        dispatch(isLoading(true));
        let params = {
            "sort[createdAt]": -1,
            "limit": limit,
            "offset": offset,
            "products.productId": product._id,
            "createdAt[gte]": filters.dateFrom,
            "createdAt[lte]": filters.dateTo
        }

        if (filters.seriesNr) {
            params["products.seriesNr"] = filters.seriesNr;
        }
        if (filters.documentNumber) {
            params["document.documentNumber"] = filters.documentNumber;
        }
        if (filters.positionCode) {
            params["positionCode"] = filters.positionCode;
        }

        let res = await Api.get("/warehouse/positionHistory", params);

        dispatch(isLoading(false));

        if (res && res.success) {
            dispatch(setProductHistory(res.documents, res.total));
        } else {
            toast('Wystąpił błąd');
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const setProductHistory = (items, total) => (dispatch) => {
    dispatch({
        type: SET_PRODUCT_HISTORY,
        items: items,
        total: total
    });
}

export const clearProductHistory = () => (dispatch) => {
    dispatch({
        type: CLEAR_PRODUCT_HISTORY
    });
}

export const setHistoryOffset = (offset) => (dispatch) => {
    dispatch({
        type: SET_HISTORY_OFFSET,
        offset: offset
    });
}

export const setHistoryFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_HISTORY_FILTERS,
        filter,
        value
    });
}

export const resetHistoryFilters = () => dispatch => {
    dispatch({
        type: RESET_HISTORY_FILTERS
    });
}

export const changeField = (name, value) => (dispatch, getState) => {
    const { product: { product } } = getState();

    let newProduct = Object.assign({}, product);
    newProduct[name] = value;
    dispatch(setProduct(newProduct));
}

export const loadProductReservations = () => async (dispatch, getState) => {
    try {
        const { product: { product } } = getState();
        dispatch(isLoading(true));

        let res = await Api.get(`/product/product/getProductReservations/${product._id}`);

        dispatch(isLoading(false));

        if (res && res.success) {
            dispatch(setProductReservations(res.documents));
        } else {
            toast('Wystąpił błąd');
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const setProductReservations = (reservations) => (dispatch) => {
    dispatch({
        type: SET_PRODUCT_RESERVATIONS,
        reservations
    });
}

export const clearProductReservations = () => (dispatch) => {
    dispatch({
        type: CLEAR_PRODUCT_RESERVATIONS
    });
}
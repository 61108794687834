import React from 'react';
import { connect } from 'react-redux';
import { clearCodes, getCodes, resetFilters, setFilters, setOffset } from '../actions/generator.list.actions';
import GeneratorListComponent from '../components/generator.list.component';

class GeneratorListContainer extends React.Component {

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props && nextProps.eanNr && nextProps.seriesNr) {
            this.props.history.push('/generator/code')
        }
    }

    render() {
        return (
            <div>
                <GeneratorListComponent {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        codes: store.list.codes,
        total: store.list.total,
        offset: store.list.offset,
        filters: store.list.filters,
        limit: store.list.limit,
        eanNr: store.code.eanNr,
        seriesNr: store.code.seriesNr
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCodes: () => dispatch(getCodes()),
        clearCodes: () => dispatch(clearCodes()),
        setFilters: (filter, value) => {
            dispatch(setFilters(filter, value))
        },
        resetFilters: () => {
            dispatch(resetFilters());
        },
        setOffset: (offset) => {
            dispatch(setOffset(offset));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneratorListContainer);
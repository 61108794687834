import { combineReducers } from 'redux';
import authReducer from '../auth/reducers/auth.reducer';
import documentsReducer from '../documents/reducers/documents.reducer';
import documentReducer from '../documents/reducers/document.reducer';
import productsReducer from '../products/reducers/products.reducer';
import productReducer from '../products/reducers/product.reducer';
import applicationReducer from '../application/reducers/appReducer';
import symfoniaImportReducer from '../documents/reducers/symfoniaImport.reducer';
import enovaImportReducer from '../documents/reducers/enovaImport.reducer';
import GeneratorListReducer from '../generator/reducers/generator.list.reducers';
import GeneratorCodeReducer from '../generator/reducers/generator.code.reducers';
import WarehousesReducer from '../warehouse/reducers/warehouses.reducer';
import WarehouseReducer from '../warehouse/reducers/warehouse.reducer';
import WarehousePositionReducer from '../warehouse/reducers/warehousePosition.reducer';
import usersReducer from "../users/reducers/users.reducer";
import userReducer from "../users/reducers/user.reducer";
import rolesReducer from "../roles/reducers/roles.reducer";
import roleReducer from "../roles/reducers/role.reducer";
import autocompleteReducer from "../application/reducers/autocomplete.reducer";
import inventoriesReducer from "../inventory/reducers/inventories.reducer";
import inventoryReducer from "../inventory/reducers/inventory.reducer";
import settingsReducer from "../settings/reducers/settings.reducer";
import profileReducer from "../profile/reducers/profile.reducer";
import reportReducer from "../report/reducers/report.reducer";
import dashboardReducer from "../dashboard/reducers/dashboard.reducer";
import administrationReducer from "../administration/reducers/administration.reducer";
import categoryTreeReducer from "../application/reducers/categoryTree.reducer";

export default combineReducers({
    auth: authReducer,
    documents: documentsReducer,
    document: documentReducer,
    symfoniaImport: symfoniaImportReducer,
    enovaImport: enovaImportReducer,
    products: productsReducer,
    product: productReducer,
    app: applicationReducer,
    list: GeneratorListReducer,
    code: GeneratorCodeReducer,
    warehouses: WarehousesReducer,
    warehouse: WarehouseReducer,
    warehousePosition: WarehousePositionReducer,
    users: usersReducer,
    roles: rolesReducer,
    user: userReducer,
    role: roleReducer,
    autocomplete: autocompleteReducer,
    inventories: inventoriesReducer,
    inventory: inventoryReducer,
    settings: settingsReducer,
    profile: profileReducer,
    report: reportReducer,
    dashboard: dashboardReducer,
    administration: administrationReducer,
    categoryTree: categoryTreeReducer
})
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Pagination from "../../../application/components/pagination";
import ComponentNavbar from '../../../application/components/componentNavbar';
import SingleDetail from '../../../application/components/singleDetail';
import Acl from "../../../services/acl.service";
import DictManager from "../../../services/dict.service";

export default class WarehousePositionDetailsComponent extends Component {

    state = {
        warehousePosition: null,
        activeTab: "products-tab",
        historyPage: 1
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props && nextProps.warehousePosition) {
            this.setState({ warehousePosition: nextProps.warehousePosition })
        }
    }

    componentDidMount() {
        const { match, getWarehousePosition, warehousePosition } = this.props;

        if (!warehousePosition) {
            let warehousePositionId = _.get(match, 'params.id', null);
            if (warehousePositionId) getWarehousePosition(warehousePositionId);
        } else this.setState({ warehousePosition: warehousePosition })

    }

    renderDetails = () => {
        const { warehousePosition } = this.props;

        if (warehousePosition) return (<div className="container">
            <div className="row">
                <div className="col-md-4">
                    <SingleDetail
                        label='Kod magazynu'
                        value={warehousePosition.warehouseCode} />
                </div>
                <div className="col-md-4 text-align-center">
                    <SingleDetail
                        label='Kod pozycji'
                        value={warehousePosition.code} />
                </div>
                <div className="col-md-4 text-align-end">
                    <SingleDetail
                        label='Typ pozycji'
                        value={DictManager.getNameByValue(1, warehousePosition.type)} />
                </div>
            </div>
        </div>)
    }

    renderPositionProducts = () => {
        return (<table className="table" style={{ width: "100%" }}>
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Lp.</th>
                    <th scope="col">Nazwa</th>
                    <th scope="col">Numer serii</th>
                    <th scope="col">Ilość</th>
                    <th scope="col">JM</th>
                    <th scope="col">Data ważności</th>
                </tr>
            </thead>
            <tbody >
                {this.getProductRows()}
            </tbody>
        </table>)
    }

    handleDetailsClick = (productId) => {
        const { history } = this.props;
        history.push(`/products/details/${productId}`)
    }

    getProductRows = () => {
        const { warehousePosition } = this.props;

        if (warehousePosition && warehousePosition.products && warehousePosition.products.length > 0) return (
            warehousePosition.products.map((positionProduct, i) => {
                return (<tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td><Link className="text-dark" to={`/products/details/${positionProduct.product}`}>{positionProduct.name}</Link></td>
                    <td>{positionProduct.seriesNr}</td>
                    <td>{positionProduct.amount}</td>
                    <td>{positionProduct.unit}</td>
                    <td>{moment(positionProduct.expirationDate).format("DD.MM.YYYY")}</td>
                </tr>)
            })
        )
    }

    setActiveTab = (name) => {
        const { activeTab } = this.state;
        if (name !== activeTab) {
            document.getElementById(activeTab).classList.remove("active");
            document.getElementById(name).classList.add("active");
            this.setState({ activeTab: name });

        }
    }

    getDetailsTabs = () => {
        const { isAdmin } = this.props;
        let hasHistoryPermission = Acl.isAllowedAction("warehouse", "positionHistory", "get", isAdmin);
        return (
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <span className="nav-link active" id="products-tab" onClick={() => this.setActiveTab("products-tab")}>Produkty</span>
                </li>
                {hasHistoryPermission && <li className="nav-item">
                    <span className="nav-link" id="history-tab" onClick={() => this.loadPositionHistory()}>Historia</span>
                </li>}
            </ul>
        )
    }

    loadPositionHistory = () => {
        const { getPositionHistory } = this.props;
        getPositionHistory();
        this.setActiveTab("history-tab");
    }

    getTabContent = () => {
        const { activeTab } = this.state;

        if (activeTab === "products-tab") {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-center">Lista produktów na pozycji magazynowej</h3>
                        </div>
                    </div>
                    <br />
                    {this.renderPositionProducts()}
                </div>
            );
        } else {
            return (<div>
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="text-center">Historia pozycji magazynowej</h3>
                    </div>
                </div>
                <br />
                {this.renderPositionHistory()}
            </div>);
        }
    }

    setPage = (page) => {
        this.setState({ historyPage: page });
    }

    renderHistoryPagination = () => {
        const { history: { offset, total, limit }, setHistoryOffset, getPositionHistory } = this.props;
        const { historyPage } = this.state;

        return (
            <Pagination
                getDocuments={getPositionHistory}
                changeOffset={setHistoryOffset}
                offset={offset}
                total={total}
                perPage={limit}
                setPage={this.setPage}
                page={historyPage}
            />
        )
    }

    renderPositionHistory = () => {
        const { history: { items } } = this.props;

        if (_.isArray(items) && items.length > 0) {
            const pagination = this.renderHistoryPagination();

            let cards = items.map((history, index) => {

                let products = history.products.map((product, i) => {
                    return (
                        <tr key={`product${i}`}>
                            <td>{i + 1}</td>
                            <td>{product.name}</td>
                            <td>{product.seriesNr}</td>
                            <td>{product.amountBefore}</td>
                            <td>{product.amountAfter}</td>
                            <td>{_.round(Math.abs(product.amountBefore - product.amountAfter), 5)}</td>
                        </tr>
                    )
                });
                let isInventoryHistory = _.includes(history.document.documentNumber, "/IN/");
                let isCorrection = !!history.correction;
                let isUndone = !!history.undone;
                let hideLink = isCorrection || isUndone;
                return (
                    <li key={index} className={`list-group-item list-group-item-action mb-1 ${isCorrection && 'bg-correction'} ${isUndone && 'bg-undone'}`}>
                        <div className="row pb-2">
                            <div className="col-8">
                                {hideLink &&
                                    <b>
                                        {history.document.documentNumber}
                                        {isCorrection && <span className="text-success"> DOKUMENT WYCOFANY</span>}
                                        {isUndone && <span className="text-danger"> DOKUMENT WYCOFANY</span>}
                                    </b>}
                                {isInventoryHistory ?
                                    !hideLink && <Link to={`/inventories/details/${history.document.documentId}`}><b>{history.document.documentNumber}</b></Link> :
                                    !hideLink && <Link to={`/documents/details/${history.document.documentId}`}><b>{history.document.documentNumber}</b></Link>}
                            </div>
                            <div className="col-4 text-right">
                                <b>{moment(history.createdAt).format("DD.MM.YYYY HH:mm")}</b>
                            </div>
                        </div>
                        <table className="table" style={{ width: "100%" }}>
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Lp.</th>
                                    <th scope="col">Produkt</th>
                                    <th scope="col">Numer serii</th>
                                    <th scope="col">Ilość przed</th>
                                    <th scope="col">Ilość po</th>
                                    <th scope="col">Różnica</th>
                                </tr>
                            </thead>
                            <tbody >
                                {products}
                            </tbody>
                        </table>
                    </li>
                );
            });
            return (<div className="d-flex flex-column">
                {pagination}
                <div>
                    <ul className="p-0">{cards}</ul>
                </div>
                {pagination}
            </div>);
        } else {
            return (
                <div className="row text-center">
                    <div className="col-12">
                        <p className="mt-3">Brak wpisów w historii</p>
                    </div>
                </div>
            )
        }
    }

    navbarButton = () => {
        const { isAdmin, warehousePosition } = this.props;
        let allowAdvancedEdit = Acl.isAllowedAction("warehouse", "position", "update", isAdmin);
        return (
            <div className="btn-group">
                <div className="dropdown">
                    <button className="btn btn-outline-info dropdown-toggle"
                        type="button" id="positionActions" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        Akcje
                        </button>
                    <div className="dropdown-menu" aria-labelledby="positionActions">
                        {allowAdvancedEdit && warehousePosition && <Link className="dropdown-item text-dark" to={`/position/edit/${warehousePosition._id}`}>Edycja</Link>}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const tabs = this.getDetailsTabs();
        const tabContent = this.getTabContent();

        return (
            <div className="container">
                <ComponentNavbar
                    title="Szczegóły pozycji magazynowej"
                    button={this.navbarButton()}
                />
                {this.renderDetails()}
                <br /> <br /> <br />
                <div className="row user-select-none mb-3">
                    <div className="col-12">
                        {tabs}
                    </div>
                </div>
                {tabContent}
            </div>
        )

    }

    componentWillUnmount() {
        const { clearWarehousePosition, clearPositionHistory, resetHistoryFilters } = this.props;
        clearWarehousePosition();
        clearPositionHistory();
        resetHistoryFilters();
    }

}
import _ from 'lodash';
import React from 'react';
import { Redirect } from 'react-router-dom';
import ComponentNavbar from '../../application/components/componentNavbar';
import Autocomplete from '../../application/containers/autocomplete.container';
import "../../index.css";

export default class Role extends React.Component {

    componentDidMount() {
        const { match: { params: { id } }, getUser } = this.props;
        if (id) {
            getUser(id);
        }
    }

    getWarehouseChips = () => {
        const { user } = this.props;
        if (_.isArray(user.warehouse) && _.get(user, 'warehouse.length', 0) > 0) {
            let chips = user.warehouse.map((warehouse, index) => {
                return (<div className="m-2 col-2 justify-content-center" key={`chip${index}`}>
                    <h6>
                        <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.deleteWarehouse("warehouse", warehouse)}>
                            {warehouse}
                            {"  "}
                            <button className="btn btn-sm btn-secondary">X</button>
                        </span>
                    </h6>
                </div>);
            });
            return (<div className="d-flex row">
                {chips}
            </div>);
        }
        return null;
    }

    renderForm = () => {
        const { user, errors } = this.props;
        const warehouseChips = this.getWarehouseChips();

        return (
            <div>
                <div className="row mt-5">
                    <div className="col-lg-12">
                        <form>
                            <div className="form-group">
                                <label>Nazwa użytkownika</label>
                                <input name="name" type="text" className="form-control" value={user.name} onChange={(e) => this.changeField("name", e)} />
                                {errors.name && <p className="text-danger">{errors.name}</p>}
                            </div>
                            <div className="form-group">
                                <label>Login</label>
                                <input name="login" type="text" className="form-control" value={user.login} onChange={(e) => this.changeField("login", e)} />
                                {errors.login && <p className="text-danger">{errors.login}</p>}
                            </div>
                            {!user._id && <div className="form-group">
                                <label>Hasło</label>
                                <input name="password" type="text" className="form-control" value={user.password} onChange={(e) => this.changeField("password", e)} />
                                {errors.password && <p className="text-danger">{errors.password}</p>}
                            </div>}
                            <div className="form-group">
                                <label>Telefon kontaktowy</label>
                                <input name="name" type="text" className="form-control" value={user.phone} onChange={(e) => this.changeField("phone", e)} />
                            </div>
                            {errors.phone && <p className="text-danger">{errors.phone}</p>}
                            <div className="form-group">
                                <label>E-mail</label>
                                <input name="email" type="email" className="form-control" value={user.email} onChange={(e) => this.changeField("email", e)} />
                            </div>
                            {errors.email && <p className="text-danger">{errors.email}</p>}
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        {user.role && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.setFieldValue("role", null)}>
                                    {user.role.name}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                    <div className="col-lg-6">
                        {warehouseChips}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <label>Rola</label>
                        <Autocomplete onSelect={(value) => this.setFieldValue("role", value)} content="role" name="userRole" />
                        {errors.role && <p className="text-danger">{errors.role}</p>}
                    </div>
                    <div className="col-lg-6">
                        <label>Magazyn</label>
                        <Autocomplete onSelect={(value) => this.setFieldValue("warehouse", value.code)} content="warehouse" name="userWarehouse" />
                        {errors.warehouse && <p className="text-danger">{errors.warehouse}</p>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 pl-5">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={user.isAdmin} onChange={(e) => this.changeCheckbox("isAdmin", e)} />
                            <label className="form-checl-label">Administrator</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={user.allowMobile} onChange={(e) => this.changeCheckbox("allowMobile", e)} />
                            <label className="form-checl-label">Dostęp do aplikacji mobilnej</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    changeField = (name, e) => {
        const { changeField } = this.props;
        changeField(name, e.target.value);
    }

    deleteWarehouse = (name, code) => {
        const { user, changeField } = this.props;
        let value = _.xor(user.warehouse, _.castArray(code));
        changeField(name, value);
    }

    setFieldValue = (name, value) => {
        const { user, changeField } = this.props;
        if (name === "warehouse") {
            if (!user.warehouse) {
                user.warehouse = [];
            }
            value = _.uniq(_.concat(user.warehouse, _.castArray(value)));
        }
        changeField(name, value);
    }

    changeCheckbox = (name, e) => {
        const { changeField } = this.props;
        changeField(name, e.target.checked);
    }

    saveUser = () => {
        const { saveUser } = this.props;
        saveUser();
    }

    render() {
        const { saved } = this.props;

        if (saved) {
            return <Redirect to="/users" />
        }
        const form = this.renderForm();
        return (
            <div className="d-flex flex-column container">
                <ComponentNavbar
                    title={"Dodawanie / Edycja użytkownika"}
                    button={null}
                />
                {form}
                <div className="row justify-content-center text-center mb-5 mt-5">
                    <button className="btn btn-primary" type="button" onClick={() => { this.saveUser() }}>Zapisz</button>
                </div>
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearUser, clearErrors } = this.props;
        clearUser();
        clearErrors();
    }
}
import moment from 'moment';
import React, { Component } from 'react';
import './generator.to.print.component.css';

export default class GeneratorToPrintComponent extends Component {

    state = {
        expirationDate: null,
        unitsInPackage: null
    }

    componentDidMount = () => {
        this.updateState(this.props);
    }

    componentWillReceiveProps(newProps) {
        this.updateState(newProps);
    }

    updateState = (props) => {
        const { expirationDate, unitsInPackage } = props;;
        const state = this.state;

        if (expirationDate) {
            state.expirationDate = this.parseExpirationDate(expirationDate);
        } else {
            state.expirationDate = 'brak';
        }

        state.unitsInPackage = unitsInPackage ? `[${unitsInPackage}szt]` : `[0szt]`;

        this.setState(state);
    }

    handleInputChange = (element, e) => {
        this.setState({
            [element]: e.target.value
        });
    }

    parseExpirationDate = (value) => {
        let date = "Brak";
        if (value && moment(value).isValid()) {
            date = `${moment(value).format("DD/MM/YYYY")}`
        }
        return date;
    }

    renderExpirationDateInput = () => {
        return <input
            className="to-print-input"
            type='text'
            onChange={(e) => this.handleInputChange('expirationDate', e)}
            defaultValue={this.state.expirationDate}
        />

    }

    renderUnitsInPackageInput = () => {
        return <input
            className="to-print-input"
            type='text'
            onChange={(e) => this.handleInputChange('unitsInPackage', e)}
            defaultValue={this.state.unitsInPackage}
        />
    }

    render() {
        const { series, img, name } = this.props;
        const expirationDateInput = this.renderExpirationDateInput();
        const unitsInPackageInput = this.renderUnitsInPackageInput();
        return (
            <div className="containerToPrint">
                <p className="text-center seriesFontSize">[{series}]</p>
                <p className="text-center"><img alt="" className="imgWidth" src={`${img}`} /></p>
                <p className="text-center dataFontSize"><strong>NAZWA:  </strong>[{name}]</p>
                <p className="text-center dataFontSize"> <strong>ZAWARTOŚĆ OPAK. ZB. : </strong>{unitsInPackageInput}</p>
                <p className="text-center dataFontSize"> <strong>TERMIN WAŻNOŚCI: </strong>{expirationDateInput}</p>
            </div>
        )
    }
}

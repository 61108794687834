import React from 'react';
import { Link } from 'react-router-dom';
import ComponentNavbar from '../../application/components/componentNavbar';
import Pagination from '../../application/components/pagination';

export default class Roles extends React.Component {

    state = {
        page: 1
    };

    componentDidMount = () => {
        const { getRoles } = this.props;
        getRoles();
    }

    renderCreateButton = () => {
        const { isAdmin } = this.props;
        if (isAdmin) {
            return (
                <div>
                    <Link to={"/roles/role"} className='btn btn-outline-primary'>
                        Dodaj
                        </Link>
                </div>
            );
        }
        return null;
    }

    renderItems = () => {
        const { roles } = this.props;

        if (roles && roles.length > 0) {
            return roles.map((role, index) => {
                return <Link to={`roles/details/${role._id}`} className="flex-fill list-group-item list-group-item-action" key={"role" + index}>{role.name}</Link>
            });
        } else {
            return (
                <ul className="d-flex justify-content-center mt-5">
                    <div>Brak roli</div>
                </ul>);
        }

    }

    changeFilter = (e, name) => {
        const { setFilters, getRoles } = this.props;
        let value = e.target.value;
        setFilters(name, value);
        this.setState({ page: 1 });
        getRoles();
    }

    resetFilters = () => {
        const { resetFilters, getRoles } = this.props;
        resetFilters();
        this.setState({ page: 1 });
        getRoles();
    }

    renderSearchBar = () => {
        const { filters } = this.props;

        return (
            <div className="mb-4">
                <div className="row">
                    <div className="col-md-10">
                        <label>Wyszukaj rolę</label>
                        <input type="text" className="form-control" placeholder="Wpisz nazwę roli" value={filters.query} onChange={(e) => this.changeFilter(e, 'query')} />
                    </div>
                    <div className="col-md-2 pt-1 text-right">
                        <button className="btn btn-secondary mt-4" onClick={() => this.resetFilters()}>Usuń filtry</button>
                    </div>
                </div>
            </div>);
    }

    setPage = (page) => {
        this.setState({ page: page });
    }

    renderPagination = () => {
        const { total, offset, limit, roles, getRoles, setOffset } = this.props;
        const { page } = this.state;

        if (roles && roles.length > 0) return (
            <Pagination
                getDocuments={getRoles}
                changeOffset={setOffset}
                offset={offset}
                total={total}
                perPage={limit}
                setPage={this.setPage}
                page={page}
            />
        )
    }

    render() {
        const items = this.renderItems();
        const createButton = this.renderCreateButton();
        const searchBar = this.renderSearchBar();
        const pagination = this.renderPagination();

        return (
            <div className="d-flex flex-column container">
                <ComponentNavbar
                    title={"Lista roli"}
                    button={createButton}
                />
                {searchBar}
                <ul className="list-group document-list mb-5">
                    {pagination}
                    {items}
                    {pagination}
                </ul>
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearRoles } = this.props;
        clearRoles();
    }
}
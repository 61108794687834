import React from 'react';
import { connect } from 'react-redux';

import { saveWarehouse, setIsUpdated, clearErrors } from '../actions/warehouse.action';

import WarehouseComponent from '../components/warehouse/warehouse.component';

class WarehouseContainer extends React.Component {

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props && nextProps.isUpdated) this.props.history.push('/warehouses')
    }

    render() {
        return (
            <div>
                <WarehouseComponent {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        isUpdated: store.warehouse.isUpdated,
        errors: store.warehouse.errors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveWarehouse: (data) => dispatch(saveWarehouse(data)),
        setIsUpdated: (data) => dispatch(setIsUpdated(data)),
        clearErrors: () => dispatch(clearErrors())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseContainer);
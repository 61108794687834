import React from 'react';
import { connect } from 'react-redux';
import { clearProduct, clearProductHistory, clearProductReservations, deleteProduct, getProduct, getProductHistory, loadProductReservations, resetHistoryFilters, setHistoryFilters, setHistoryOffset } from '../actions/product.actions';
import ProductDetails from '../components/productDetails.component';
import '../products.css';

class ProductDetailsContainer extends React.Component {
    render() {
        return (
            <div>
                <ProductDetails {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        product: store.product
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProduct: (id) => {
            dispatch(getProduct(id))
        },
        clearProduct: () => {
            dispatch(clearProduct())
        },
        deleteProduct: (id, history) => {
            dispatch(deleteProduct(id, history));
        },
        getProductHistory: () => {
            dispatch(getProductHistory());
        },
        clearProductHistory: () => {
            dispatch(clearProductHistory());
        },
        setHistoryOffset: (offset) => {
            dispatch(setHistoryOffset(offset));
        },
        setHistoryFilters: (name, value) => {
            dispatch(setHistoryFilters(name, value));
        },
        resetHistoryFilters: () => {
            dispatch(resetHistoryFilters());
        },
        loadProductReservations: () => {
            dispatch(loadProductReservations());
        },
        clearProductReservations: () => {
            dispatch(clearProductReservations());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsContainer);
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Acl from "../../services/acl.service";
import ComponentNavbar from '../../application/components/componentNavbar';
import SingleDetail from '../../application/components/singleDetail';
import Pagination from "../../application/components/pagination";
import Autocomplete from "../../application/containers/autocomplete.container";
import DeleteConfirmationModal from '../../application/components/deleteConfirmationModal';

export default class InventoryDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            index: null,
            historyId: null,
            showDeleteModal: false,
            showEditModal: false,
            showCompleteModal: false,
            activeTab: "positions-tab",
            creatorFilter: "",
            positionFilter: "",
            historyPage: 1
        }
    }

    handleOpenDeleteModal = (historyId, index) => {
        this.setState({
            index: index,
            historyId: historyId,
            showDeleteModal: true
        });
    }

    handleCloseDeleteModal = () => {
        this.setState({
            index: null,
            historyId: null,
            showDeleteModal: false
        });
    }

    handleOpenEditModal = (positionCode, index) => {
        this.setState({
            index: index,
            positionCode: positionCode,
            showEditModal: true
        });
    }

    handleCloseEditModal = () => {
        this.setState({
            index: null,
            positionCode: null,
            showEditModal: false
        });
    }

    handleOpenCompleteModal = () => {
        this.setState({
            showCompleteModal: true
        });
    }

    handleCloseCompleteModal = () => {
        this.setState({
            showCompleteModal: false
        });
    }

    componentDidMount() {
        const { match: { params: { id } }, getInventory } = this.props;
        getInventory(id);
    }

    deleteInventory = () => {
        const { deleteInventory } = this.props;
        deleteInventory();
    }

    closeInventory = () => {
        const { closeInventory } = this.props;
        closeInventory();
        this.handleCloseCompleteModal();
    }

    generateReport = () => {
        const { generateReport } = this.props;
        generateReport();
    }

    renderCloseButton = () => {
        const { isAdmin, inventory } = this.props;
        let showCloseButton = Acl.isAllowedAction("warehouse", "inventory", "update", isAdmin) && !inventory.completed;
        let showReportButton = Acl.isAllowedAction("warehouse", "inventory", "generateReport", isAdmin) && inventory.completed;
        if (showCloseButton) {
            return (
                <button
                    type='button'
                    className='btn btn-outline-primary'
                    onClick={() => this.handleOpenCompleteModal()}>Zamknij inwentaryzację</button>
            );
        } else if (showReportButton) {
            return (
                <button
                    type='button'
                    className='btn btn-outline-success'
                    onClick={() => this.generateReport()}>Generuj raport</button>
            );
        }
        return null;
    }

    renderDetails = () => {
        const { inventory } = this.props;
        if (inventory) {
            return (
                <div className="row">
                    <div className="col">
                        <SingleDetail
                            label={'Nazwa'}
                            value={inventory.name || "-"}
                        />
                        <SingleDetail
                            label={'Numer inwentaryzacji'}
                            value={inventory.inventoryNumber || "-"}
                        />
                        <SingleDetail
                            label={'Magazyn'}
                            value={inventory.warehouse ? inventory.warehouse.name : "-"}
                        />
                    </div>
                    <div className="col">
                        <SingleDetail
                            label={'Data utworzenia'}
                            value={moment(inventory.createdAt).format("DD.MM.YYYY HH:mm")}
                        />
                        {inventory.completedAt && <SingleDetail
                            label={'Data zamknięcia'}
                            value={moment(inventory.completedAt).format("DD.MM.YYYY HH:mm")}
                        />}
                        <SingleDetail
                            label={'Zamknięta'}
                            value={inventory.completed ? "Tak" : "Nie"}
                        />
                    </div>
                </div>
            )
        }
        return null;
    }

    setActiveTab = (name) => {
        const { activeTab } = this.state;
        if (name !== activeTab) {
            document.getElementById(activeTab).classList.remove("active");
            document.getElementById(name).classList.add("active");
            this.setState({ activeTab: name });

        }
    }

    getDetailsTabs = () => {
        return (
            <ul className="nav nav-tabs user-select-none">
                <li className="nav-item">
                    <span className="nav-link active" id="positions-tab" onClick={() => this.setActiveTab("positions-tab")}>Historia</span>
                </li>
                <li className="nav-item">
                    <span className="nav-link" id="left-tab" onClick={() => this.setActiveTab("left-tab")}>Nieodczytane pozycje</span>
                </li>
                <li className="nav-item">
                    <span className="nav-link" id="inProgress-tab" onClick={() => this.setActiveTab("inProgress-tab")}>W trakcie inwentaryzacji</span>
                </li>
            </ul>
        )
    }

    getTabContent = () => {
        const { activeTab } = this.state;

        if (activeTab === "positions-tab") {
            return (
                <div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <h3 className="text-center">Historia</h3>
                        </div>
                    </div>
                    <br />
                    {this.renderPositions()}
                </div>
            );
        } else if (activeTab === "left-tab") {
            return (
                <div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <h3 className="text-center">Nieodczytane pozycje</h3>
                        </div>
                    </div>
                    <br />
                    {this.renderPositionsLeft()}
                </div>
            );
        } else if (activeTab === "inProgress-tab") {
            return (
                <div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <h3 className="text-center">Pozycje w trakcie inwentaryzacji</h3>
                        </div>
                    </div>
                    <br />
                    {this.renderPositionsInProgress()}
                </div>
            );
        } else {
            return null;
        }
    }

    renderPositionsLeft = () => {
        const { inventory } = this.props;

        if (_.get(inventory, 'statistics.leftCodes.length', 0) > 0) {

            let positons = inventory.statistics.leftCodes.map((position) => {
                return <li key={`pos-${position}`} className="flex-fill list-group-item list-group-item-action">{position}</li>;
            });

            return <ul className="list-group document-list mb-5 p-0">
                <div className="row mb-2">
                    <div className="col-6">
                        <p>Odczytanych pozycji: {inventory.statistics.completed}</p>
                    </div>
                    <div className="col-6 text-right">
                        <p>Pozostało do odczytania {inventory.statistics.left} z {inventory.statistics.all} pozycji</p>
                    </div>
                </div>
                {positons}
            </ul>

        } else {
            return (
                <div className="row text-center">
                    <div className="col-12">
                        <p className="mt-3">Wszystkie pozycje zostały zeskanowane</p>
                    </div>
                </div>
            )
        }
    }

    handleResetPosition = () => {
        const { historyId, index } = this.state;
        const { resetInventoryPosition } = this.props;
        resetInventoryPosition(historyId, index);
        this.handleCloseDeleteModal();
    }

    handleAllowInventoryEdit = () => {
        const { positionCode, index } = this.state;
        const { allowInventoryEdit } = this.props;
        allowInventoryEdit(positionCode, index);
        this.handleCloseEditModal();
    }

    renderPositions = () => {
        const { inventory, history, isAdmin } = this.props;
        const pagination = this.renderHistoryPagination();
        const searchBar = this.renderSearchBar();
        let cards = null;

        if (_.get(history, "items.length", 0) > 0) {

            cards = history.items.map((record, index) => {
                let products = record.products.map((product, i) => {
                    return (
                        <tr key={`product${i}`}>
                            <td>{i + 1}</td>
                            <td>{product.name}</td>
                            <td>{product.seriesNr}</td>
                            <td>{product.amountBefore}</td>
                            <td>{product.amountAfter}</td>
                            <td>{Math.abs(product.amountBefore - product.amountAfter)}</td>
                        </tr>
                    )
                });
                let canDelete = !inventory.completed && Acl.isAllowedAction("warehouse", "inventory", "resetInventoryPosition", isAdmin);
                let canEdit = !inventory.completed && !_.get(record, "position.allowEdit") && Acl.isAllowedAction("warehouse", "inventory", "allowInventoryEdit", isAdmin);
                return (
                    <li key={index} className="flex-fill list-group-item list-group-item-action mb-1">
                        <div className="row pb-2">
                            <div className="col-2">
                                <b>{moment(record.createdAt).format("DD.MM.YYYY HH:mm")}</b>
                            </div>
                            <div className="col-6 text-center">
                                <Link to={`/warehouses/position/${_.get(record, "position._id")}`}><b>{record.positionCode}</b></Link> - <b>{_.get(record, 'creator.name', "?")}</b>
                            </div>
                            <div className="col-3 text-right">
                                {canEdit && <button className="btn btn-sm btn-secondary" onClick={() => this.handleOpenEditModal(record.positionCode, index)}>Zezwól na edycję</button>}
                            </div>
                            <div className="col-1 text-right">
                                {canDelete && <button className="btn btn-sm btn-danger" onClick={() => this.handleOpenDeleteModal(record._id, index)}>Usuń</button>}
                            </div>
                        </div>
                        <table className="table" style={{ width: "100%" }}>
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Lp.</th>
                                    <th scope="col">Produkt</th>
                                    <th scope="col">Numer serii</th>
                                    <th scope="col">Ilość przed</th>
                                    <th scope="col">Ilość po</th>
                                    <th scope="col">Różnica</th>
                                </tr>
                            </thead>
                            <tbody >
                                {products}
                            </tbody>
                        </table>
                    </li>
                );
            });
        } else {
            cards = <div className="row text-center">
                <div className="col-12">
                    <p className="mt-3">Brak odczytanych pozycji</p>
                </div>
            </div>
        }

        return (<ul>
            {searchBar}
            {pagination}
            {cards}
            {pagination}
        </ul>);
    }

    renderPositionsInProgress = () => {
        const { inventory } = this.props;
        let cards = null;
        let list = null;

        if (_.get(inventory, "inProgress.length", 0) > 0) {

            list = inventory.inProgress.map((record) => {
                return <a className="text-dark" key={`link-${record.positionFrom}`} href={`#${record.positionFrom}`}>{`${record.positionFrom}, `}</a>;
            });

            cards = inventory.inProgress.map((record, index) => {
                let products = record.products.map((product, i) => {
                    return (
                        <tr key={`product${i}`}>
                            <td>{i + 1}</td>
                            <td><Link className="text-dark" to={`/products/details/${product.productId}`}>{product.name}</Link></td>
                            <td>{product.seriesNr}</td>
                            <td>{product.amount}</td>
                        </tr>
                    )
                });

                return (
                    <li key={index} className="flex-fill list-group-item list-group-item-action mb-1" id={`${record.positionFrom}`}>
                        <div className="row pb-2">
                            <div className="col-4">
                                <b>{moment(record.createdAt).format("DD.MM.YYYY HH:mm")}</b>
                            </div>
                            <div className="col-4 text-center">
                                <b>{record.positionFrom}</b>
                            </div>
                            <div className="col-4 text-right">
                                <b>{_.get(record, 'creator.name', "-")}</b>
                            </div>
                        </div>
                        <table className="table" style={{ width: "100%" }}>
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Lp.</th>
                                    <th scope="col">Produkt</th>
                                    <th scope="col">Numer serii</th>
                                    <th scope="col">Ilość</th>
                                </tr>
                            </thead>
                            <tbody >
                                {products}
                            </tbody>
                        </table>
                    </li>
                );
            });
        } else {
            cards = <div className="row text-center">
                <div className="col-12">
                    <p className="mt-3">Brak pozycji w trakcie inwentaryzacji</p>
                </div>
            </div>
        }

        return (<ul>
            <p className="mb-3"><b>Odnośniki:</b> {list}</p>
            {cards}
        </ul>);
    }

    setPage = (page) => {
        this.setState({ historyPage: page });
    }

    renderHistoryPagination = () => {
        const { history: { offset, total, limit }, setHistoryOffset, getInventoryHistory } = this.props;
        const { historyPage } = this.state;

        return (
            <Pagination
                getDocuments={getInventoryHistory}
                changeOffset={setHistoryOffset}
                offset={offset}
                total={total}
                perPage={limit}
                setPage={this.setPage}
                page={historyPage}
            />
        )
    }

    changeFilter = (e, name) => {
        const { setHistoryFilters, getInventoryHistory } = this.props;
        let value = e.target.value;
        setHistoryFilters(name, value);
        this.setState({ historyPage: 1 });
        getInventoryHistory();
    }

    changeAutocompleteFilter = (name, value) => {
        const { setHistoryFilters, getInventoryHistory } = this.props;
        setHistoryFilters(name, value);
        this.setState({ historyPage: 1 });
        getInventoryHistory();
    }

    resetFilters = () => {
        const { resetHistoryFilters, getInventoryHistory } = this.props;
        resetHistoryFilters();
        this.setState({ historyPage: 1 });
        getInventoryHistory();
    }

    renderSearchBar = () => {
        const { history: { filters } } = this.props;

        return (
            <div className="mb-4">
                <div className="row">
                    <div className="col-md-3 ">
                        {filters.user && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("user", null)}>
                                    {filters.user.name}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                    <div className="col-md-3">
                        {filters.product && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("product", null)}>
                                    {filters.product.name}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label>Wyszukaj użytkownika</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("user", value)} content="user" name="userSearch" />
                    </div>
                    <div className="col-md-3">
                        <label>Produkt</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("product", value)} content="product" name="roleSearch" />
                    </div>
                    <div className="col-md-2">
                        <label>Numer serii</label>
                        <input onChange={(e) => this.changeFilter(e, "seriesNr")} value={filters.seriesNr} className="form-control" placeholder="Wyszukaj serię" />
                    </div>
                    <div className="col-md-2">
                        <label>Pozycja</label>
                        <input onChange={(e) => this.changeFilter(e, "positionCode")} value={filters.positionCode} className="form-control" placeholder="Wyszukaj pozycję" />
                    </div>
                    <div className="col-md-2 pt-1 text-right">
                        <button className="btn btn-secondary mt-4" onClick={() => this.resetFilters()}>Usuń filtry</button>
                    </div>
                </div>
            </div>);
    }

    renderDeleteModal = () => {
        const { showDeleteModal } = this.state;
        if (!showDeleteModal) {
            return null;
        }
        return (
            <DeleteConfirmationModal
                title="Usuwanie odczytu"
                msg="Czy na pewno chcesz usunąć odczyt pozycji?"
                handleCloseModal={this.handleCloseDeleteModal}
                confirmAction={this.handleResetPosition}
            />);
    }

    renderEditModal = () => {
        const { showEditModal } = this.state;
        if (!showEditModal) {
            return null;
        }
        return (
            <DeleteConfirmationModal
                title="Edycja pozycji"
                msg="Czy na pewno zezwolić na edycję odczytu pozycji?"
                handleCloseModal={this.handleCloseEditModal}
                confirmAction={this.handleAllowInventoryEdit}
            />);
    }

    renderCompleteModal = () => {
        const { showCompleteModal } = this.state;
        if (!showCompleteModal) {
            return null;
        }
        return (
            <DeleteConfirmationModal
                title="Zamknięcie inwentaryzacji"
                msg="Czy na pewno chcesz zamknąć inwentaryzację?"
                handleCloseModal={this.handleCloseCompleteModal}
                confirmAction={this.closeInventory}
            />);
    }

    renderProgressBar = () => {
        const { inventory } = this.props;
        if (inventory.completed) {
            return null;
        }

        let all = _.get(inventory, 'statistics.all', 1);
        let progress = ((_.get(inventory, 'statistics.completed', 0) / all) * 100).toFixed(2);

        return (
            <div className="row mt-3 text-center" >
                <h6 className="ml-3">Postęp - {`${progress}%`}</h6>
                <div className="col-12">
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ "width": `${progress}%` }} title={`${progress}%`}></div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const closeButton = this.renderCloseButton();
        const details = this.renderDetails();
        const tabs = this.getDetailsTabs();
        const tabsContent = this.getTabContent();
        const deleteModal = this.renderDeleteModal();
        const editModal = this.renderEditModal();
        const completeModal = this.renderCompleteModal();
        const progressBar = this.renderProgressBar();

        return (
            <div className="d-flex flex-column container product-container">
                {deleteModal}
                {editModal}
                {completeModal}
                <ComponentNavbar
                    title="Szczegóły inwentaryzacji"
                    button={closeButton}
                />
                {details}
                {progressBar}
                <hr />
                {tabs}
                {tabsContent}
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearInventory } = this.props;
        clearInventory();
    }
}
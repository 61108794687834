import produce from 'immer';

import { SET_DASHBOARD, CLEAR_DASHBOARD } from '../actions/dashboard.actions'

const initial_state = {
    dashboard: null
}

const dashboardReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_DASHBOARD:
                draft.dashboard = action.dashboard;
                break;
            case CLEAR_DASHBOARD:
                draft.dashboard = null;
                break;
            default: return draft;
        }
    })
}

export default dashboardReducer;
import React from "react";
import DictManager from '../../services/dict.service';
import Autocomplete from '../../application/containers/autocomplete.container';
import _ from 'lodash';

export default class DictionaryForm extends React.Component {

    setDefaultPosition = (code, values, index) => {
        const { setSimpleValue } = this.props;
        if (!_.isArray(values)) {
            values = [];
        }
        values.push(code);
        setSimpleValue(_.uniq(values), "defaultPosition", index);
    }

    getDefaultPositionsLabels = (obj) => {
        if (_.isArray(obj.defaultPosition) && !obj.protected) {
            return obj.defaultPosition.map((pos, index) => {
                return (<div className="badge badge-secondary">
                    {pos}
                </div>);
            })
        }
        return null;
    }

    render() {
        const { item, setValue, deleteValue, setSimpleValue } = this.props;
        if (!item) {
            return null;
        }
        let inputs = item.values.map((obj, index) => {
            const defaultPositions = this.getDefaultPositionsLabels(obj);
            return (
                <div key={index}>
                    <div className="row">
                        <div className="col-4 offset-6">
                            {defaultPositions}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <label>Typ dokumentu</label>
                            <select className="form-control" disabled={obj.protected} value={obj.name || "0"} onChange={(e) => setValue(e, 'name', index)}>
                                {DictManager.getOptionsByType(4, "importType", "importType")}
                            </select>
                        </div>
                        <div className="col-4">
                            <label>Magazyn</label>
                            {obj.value && <div className="input-group">
                                <input type="text" className="form-control" value={obj.value} disabled={obj.protected} readOnly />
                                {!obj.protected && <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button" onClick={() => setSimpleValue(null, "value", index)}>Zmień</button>
                                </div>}
                            </div>}
                            {!obj.value && <Autocomplete onSelect={(value) => setSimpleValue(value.code, "value", index)} content="warehouse" name={`warehouseSearch${index}`} />}
                        </div>
                        <div className="col-4">
                            <label>Domyślne pozycje</label>
                            {obj.protected &&  <input type="text" className="form-control" value={_.join(obj.defaultPosition, ", ")} readOnly />}
                            {!obj.protected && <Autocomplete onSelect={(value) => this.setDefaultPosition(value.code, obj.defaultPosition, index)} content="position" name={`positionSearch${index}`} />}
                        </div>
                        {!obj.protected && <div className="col-2 pt-1">
                            <button className="btn btn-danger mt-4" onClick={() => { deleteValue(index) }}>Usuń</button>
                        </div>}
                    </div>
                </div>

            );
        });

        return (<div>
            <div className="alert alert-warning">
                Ustawienie definiuje typy dokumentów, które będą automatycznie zatwierdzane przy imporcie na danych magazynach.
            </div>
            {inputs}
        </div>);
    }
}

import produce from 'immer';

import { SET_ROLES, CLEAR_ROLES, SET_FILTERS, RESET_FILTERS, SET_OFFSET } from '../actions/roles.action'

const initial_state = {
    roles: [],
    filters: {
        query: ""
    },
    total: 0,
    offset: 0,
    limit: 25
}

const rolesReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_ROLES:
                draft.roles = action.roles;
                draft.total = action.total;
                break;
            case CLEAR_ROLES:
                draft.roles = [];
                draft.total = 0;
                break;
            case SET_FILTERS:
                draft.filters[action.filter] = action.value;
                draft.offset = 0;
                break;
            case RESET_FILTERS:
                draft.filters = {
                    role: "",
                    warehouse: "",
                    query: ""
                };
                draft.offset = 0;
                break;
            case SET_OFFSET: {
                draft.offset = action.offset;
                break;
            }
            default: return draft;
        }
    })
}

export default rolesReducer;
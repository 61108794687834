import React from 'react';
import { connect } from 'react-redux';
import { getInventories, clearInventories, setFilters, resetFilters, setOffset } from '../actions/inventories.action'
import Inventories from '../components/inventories.component';

class InventoriesContainer extends React.Component {
    render() {
        return (
            <div>
                <Inventories {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        inventories: store.inventories.inventories,
        total: store.inventories.total,
        isAdmin: store.auth.isAdmin,
        filters: store.inventories.filters,
        limit: store.inventories.limit,
        offset: store.inventories.offset
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getInventories: () => {
            dispatch(getInventories());
        },
        clearInventories: () => {
            dispatch(clearInventories());
        },
        setFilters: (filter, value) => {
            dispatch(setFilters(filter, value))
        },
        resetFilters: () => {
            dispatch(resetFilters());
        },
        setOffset: (offset) => {
            dispatch(setOffset(offset));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoriesContainer);
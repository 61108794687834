import React from 'react';
import { connect } from 'react-redux';
import { getWarehouse } from '../actions/warehouse.action';
import { getWarehouses, setOffset, setFilters, resetFilters } from '../actions/warehouses.action';
import WarehousesComponent from '../components/warehouse/warehouses.component';

class WarehouseListContainer extends React.Component {

    componentWillReceiveProps(nextProps) {
        if (nextProps.warehouse && nextProps.warehouse._id && nextProps.warehouse !== this.props.warehouse) {
            this.props.history.push(`/warehouses/details/${nextProps.warehouse._id}`)
        }
    }

    render() {
        return (
            <div>
                <WarehousesComponent {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        warehouses: store.warehouses.warehouses,
        offset: store.warehouses.offset,
        filters: store.warehouses.filters,
        limit: store.warehouses.limit,
        warehouse: store.warehouse.warehouse,
        total: store.warehouses.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getWarehouses: () => {
            dispatch(getWarehouses());
        },
        getWarehouse: (warehouseId) => {
            dispatch(getWarehouse(warehouseId));
        },
        setFilters: (filter, value) => {
            dispatch(setFilters(filter, value))
        },
        resetFilters: () => {
            dispatch(resetFilters());
        },
        setOffset: (offset) => {
            dispatch(setOffset(offset));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseListContainer);
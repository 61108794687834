import React, { Component } from 'react';

import WarehousePositionsRecordComponent from './warehousePositionsRecord.component';

export default class WarehousePositionsComponent extends Component {

    state = {
        positions: [],
        warehouseId: null
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props && nextProps.positions) {
            this.setState({ positions: nextProps.positions })
        }
    }

    renderList = () => {
        const { positions } = this.state;
        const { isAdmin } = this.props;

        if (positions && positions.length > 0) return (
            <ul className="list">
                {positions.map((position, index) => {
                    return (
                        <li key={index} className="flex-fill list-group-item list-group-item-action">
                            <WarehousePositionsRecordComponent
                                positionId={position._id}
                                warehouseCode={position.warehouseCode}
                                positionCode={position.code}
                                barcode={position.barcode}
                                type={position.type}
                                handleOnClick={() => this.props.onDeletePosition(position._id)}
                                positionDetailsClick={(positionId) => this.props.positionDetailsClick(positionId)}
                                isAdmin={isAdmin} />
                        </li>
                    )
                })}
            </ul>
        )
    }

    render() {

        return (
            <ul className="list-group document-list">
                {this.renderList()}
            </ul>
        )
    }

}
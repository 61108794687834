import React, { Component } from 'react';

export default class WarehouseToPrintComponent extends Component {

    render() {

        return (
            <div className="d-flex flex-column mt-2">
                <p className="text-center position-code">[{this.props.positionCode}]</p>
                <p className="text-center position-"><img alt="" className="barcode-to-print" src={`${this.props.barcode}`} /></p>
            </div>
        )
    }

}

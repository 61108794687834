import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import ComponentNavbar from '../../application/components/componentNavbar';
import Pagination from '../../application/components/pagination';
import Acl from "../../services/acl.service";

export default class Inventories extends React.Component {

    state = {
        page: 1
    };

    componentDidMount = () => {
        const { getInventories } = this.props;
        getInventories();
    }

    renderCreateButton = () => {
        const { isAdmin } = this.props;
        let showButton = Acl.isAllowedAction("warehouse", "inventory", "create", isAdmin);
        if (showButton) {
            return (
                <div>
                    <Link to={"/inventories/inventory"} className='btn btn-outline-primary'>
                        Dodaj
                    </Link>
                </div>
            );
        }
        return null;
    }

    renderItems = () => {
        const { inventories } = this.props;

        if (inventories && inventories.length > 0) {
            return inventories.map((inventory, index) => {
                return <Link key={index} to={`inventories/details/${inventory._id}`} className="flex-fill list-group-item list-group-item-action">{inventory.name} - {inventory.inventoryNumber} ({moment(inventory.createdAt).format("DD.MM.YYYY HH:mm")})</Link>
            });
        } else {
            return (
                <ul className="d-flex justify-content-center mt-5">
                    <div>Brak inwentaryzacji</div>
                </ul>);
        }
    }

    changeFilter = (e, name) => {
        const { setFilters, getInventories } = this.props;
        let value = e.target.value;

        if (name === "dateFrom") {
            if (value === "") {
                value = moment().startOf('month').toISOString();
            } else {
                value = moment(value).startOf('day').toISOString();
            }
        }
        if (name === "dateTo") {
            if (value === "") {
                value = moment().endOf('day').toISOString();
            } else {
                value = moment(value).endOf('day').toISOString();
            }
        }
        if (name === "completed") {
            if (value === "Wybierz") {
                value = undefined;
            }
        }

        setFilters(name, value);
        this.setState({ page: 1 });
        getInventories();
    }

    resetFilters = () => {
        const { resetFilters, getInventories } = this.props;
        resetFilters();
        this.setState({ page: 1 });
        getInventories();
    }

    renderSearchBar = () => {
        const { filters } = this.props;

        return (
            <div>
                <div className="row mb-5">
                    <div className="col-md-4">
                        <label>Wyszukaj inwentaryzację</label>
                        <input type="text" className="form-control" placeholder="Wpisz nazwę inwentaryzacji" value={filters.query} onChange={(e) => this.changeFilter(e, 'query')} />
                    </div>
                    <div className="col-md-2">
                        <label>Zakmnięta</label>
                        <select type="text" className="form-control" value={filters.completed || "Wybierz"} onChange={(e) => this.changeFilter(e, 'completed')}>
                            <option value="Wybierz">Wybierz</option>
                            <option value="true">Tak</option>
                            <option value="false">Nie</option>
                        </select>
                    </div>

                    <div className="col-md-2">
                        <label>Data utworzenia (od)</label>
                        <input type="date" className="form-control" value={moment(filters.dateFrom).format("YYYY-MM-DD")} onChange={(e) => this.changeFilter(e, 'dateFrom')} />
                    </div>
                    <div className="col-md-2">
                        <label>Data utworzenia (do)</label>
                        <input type="date" className="form-control" value={moment(filters.dateTo).format("YYYY-MM-DD")} onChange={(e) => this.changeFilter(e, 'dateTo')} />
                    </div>
                    <div className="col-md-2 pt-1 text-right">
                        <button className="btn btn-secondary mt-4" onClick={() => this.resetFilters()}>Usuń filtry</button>
                    </div>
                </div>

            </div>

        )
    }

    setPage = (page) => {
        this.setState({ page: page });
    }

    renderPagination = () => {
        const { total, offset, limit, inventories, getInventories, setOffset } = this.props;
        const { page } = this.state;

        if (inventories && inventories.length > 0) return (
            <Pagination
                getDocuments={getInventories}
                changeOffset={setOffset}
                offset={offset}
                total={total}
                perPage={limit}
                setPage={this.setPage}
                page={page}
            />
        )
    }

    render() {
        const items = this.renderItems();
        const createButton = this.renderCreateButton();
        const searchBar = this.renderSearchBar();
        const pagination = this.renderPagination();

        return (
            <div className="d-flex flex-column container">
                <ComponentNavbar
                    title={"Lista inwentaryzacji"}
                    button={createButton}
                />
                {searchBar}
                <ul className="list-group document-list">
                    {pagination}
                    {items}
                    {pagination}
                </ul>
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearInventories } = this.props;
        clearInventories();
    }
}
import produce from 'immer';

import { SET_CODES, CLEAR_CODES, SET_FILTERS, RESET_FILTERS, SET_OFFSET } from '../actions/generator.list.actions'

const initial_state = {
    codes: null,
    filters: {
        eanNr: "",
        seriesNr: "",
        name: ""
    },
    total: 0,
    limit: 25,
    offset: 0
}

const generatorListReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_CODES:
                draft.codes = action.codes;
                draft.total = action.total;
                break;
            case CLEAR_CODES:
                draft.codes = null;
                draft.total = 0;
                break;
            case SET_FILTERS:
                draft.filters[action.filter] = action.value
                draft.offset = 0;
                break;
            case RESET_FILTERS:
                draft.filters = {
                    ean: "",
                    seriesNr: "",
                    name: ""
                };
                draft.offset = 0;
                break;
            case SET_OFFSET:
                draft.offset = action.offset;
                break;
            default: return draft;
        }
    });
}

export default generatorListReducer;
import React from 'react';
import { connect } from 'react-redux';
import { clearItems, clearItem } from '../actions/administration.actions';
import Administration from '../components/administration.component';

class AdministrationContainer extends React.Component {
    render() {
        return (
            <div>
                <Administration {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        items: store.administration.items
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clearItems: () => {
            dispatch(clearItems());
        },
        clearItem: () => {
            dispatch(clearItem());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationContainer);
import React, { Component } from 'react';
import BasicInput from '../../../application/components/basicInput';
import ComponentNavbar from '../../../application/components/componentNavbar';


export default class WarehouseComponent extends Component {

    state = {
        warehouseName: '',
        warehouseCode: ''
    }

    handleSaveCodeButton = () => {
        const { saveWarehouse } = this.props;
        const { warehouseName, warehouseCode } = this.state;
        const data = {
            name: warehouseName,
            code: warehouseCode,
            foreignId: warehouseCode
        }

        saveWarehouse(data);
    }

    onInputChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    renderNameError = () => {
        const { errors } = this.props

        if (errors && errors.name) return <div><span className="text-danger">{errors.name}</span><br /></div>
        return null
    }

    renderCodeError = () => {
        const { errors } = this.props

        if (errors && errors.code) return <div><span className="text-danger">{errors.code}</span><br /></div>
        return null
    }

    render() {

        return (
            <div className="d-flex flex-column container product-container">
                <ComponentNavbar
                    title="Tworzenie magazynu"
                />
                <div className="row mt-5">
                    <div className="col-6">
                        <BasicInput
                            className="form-group"
                            type="text"
                            id="warehouseName"
                            label="Podaj nazwe magazynu"
                            placeholder="Wpisz nazwe magazynu..."
                            onChange={this.onInputChange}
                            value={this.state.warehouseName} />
                        {this.renderNameError()}
                    </div>
                    <div className="col-6">
                        <BasicInput
                            className="form-group"
                            type="text"
                            id="warehouseCode"
                            label="Podaj kod magazynu"
                            placeholder="Wpisz kod magazynu.."
                            onChange={this.onInputChange}
                            value={this.state.warehouseCode} />
                        {this.renderCodeError()}
                    </div>
                </div>
                <div className="justify-content-center mb-5 mt-5 text-center">
                    <button onClick={this.handleSaveCodeButton} type="button" className="btn btn-primary">Zapisz</button>
                </div>
            </div>
        )

    }

    componentWillUnmount() {
        const { setIsUpdated, clearErrors } = this.props;
        setIsUpdated(false);
        clearErrors()
    }

}
import React from 'react';
import { connect } from 'react-redux';

import { saveWarehousePosition, setWarehouseId, setIsUpdated, clearErrors, getWarehousePositionType } from '../actions/warehousePosition.action'
import WarehousePositionComponent from '../components/warehousePositions/warehousePosition.component';

class WarehousePositionsContainer extends React.Component {

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props && nextProps.isUpdated && nextProps.warehouseId) {
            this.props.history.push(`/warehouses/details/${this.props.warehouseId}`)
        }
    }

    render() {
        return (
            <div>
                <WarehousePositionComponent {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        isUpdated: store.warehousePosition.isUpdated,
        warehouseId: store.warehousePosition.warehouseId,
        errors: store.warehousePosition.errors,
        warehousePositionTypes: store.warehousePosition.warehousePositionTypes
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveWarehousePosition: (data) => dispatch(saveWarehousePosition(data)),
        setWarehouseId: (warehouseId) => dispatch(setWarehouseId(warehouseId)),
        setIsUpdated: (val) => dispatch(setIsUpdated(val)),
        clearErrors: () => dispatch(clearErrors()),
        getWarehousePositionType: () => dispatch(getWarehousePositionType())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehousePositionsContainer);
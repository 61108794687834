import _ from 'lodash';
import moment from "moment";
import React from 'react';
import ComponentNavbar from '../../../application/components/componentNavbar';
import DictManager from '../../../services/dict.service';

export default class DocumentEdit extends React.Component {

    componentDidMount = () => {
        const { getWarehousePosition } = this.props;
        const { id } = this.props.match.params;
        getWarehousePosition(id);
    }

    render() {
        const { warehousePosition, setFieldValue, saveAdvancedEdit, history } = this.props;
        if (!warehousePosition) {
            return null;
        }

        let positionTypes = DictManager.getOptionsByType(1);
        const products = this.renderProducts();

        return (<div className="container mb-5">
            <ComponentNavbar
                title="Edycja dokumentu"
                button={null}
            />
            <div className="row">
                <div className="col-4">
                    <div className="form-group">
                        <label>Kod magazynu</label>
                        <input type="text" className="form-control" value={warehousePosition.warehouseCode || ""} readOnly={true} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>Kod pozycji</label>
                        <input type="text" className="form-control" value={warehousePosition.code || ""} readOnly={true} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>Typ pozycji</label>
                        <select className="form-control" value={warehousePosition.type || "0"} onChange={(e) => setFieldValue("type", e.target.value)}>
                            {positionTypes}
                        </select>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <hr />
                    <h5>Produkty</h5>
                    {products}
                </div>
                <div className="col-12 text-center">
                    <hr />
                    <button className="btn btn-primary" onClick={() => saveAdvancedEdit(history)}>Zapisz</button>
                </div>
            </div>
        </div>)
    }

    renderProducts() {
        const { warehousePosition, setFieldValue } = this.props;

        if(_.isEmpty(warehousePosition.products)) {
            return <p className="mt-5 mb-5">Brak produktów na pozycji</p>
        }

        return warehousePosition.products.map((product, productIndex) => {
            return (<div className="form-group row" key={productIndex}>
                <div className="col-4">
                    <label>Nazwa</label>
                    <input
                        type="text"
                        className="form-control"
                        value={product.name || ""}
                        readOnly={true}
                        title={product.name}
                    />
                </div>
                <div className="col-4">
                    <label>Numer serii</label>
                    <input
                        type="text"
                        className="form-control"
                        value={product.seriesNr || ""}
                        placeholder="Wpisz numer serii"
                        onChange={(e) => setFieldValue(`products[${productIndex}].seriesNr`, e.target.value)}
                    />
                </div>
                <div className="col-2">
                    <label>Data ważności</label>
                    <input
                        type="date"
                        className="form-control"
                        value={moment(product.expirationDate).format("YYYY-MM-DD")}
                        onChange={(e) => setFieldValue(`products[${productIndex}].expirationDate`, moment(e.target.value, "YYYY-MM-DD").toISOString())}
                    />
                </div>
                <div className="col-2">
                    <label>Ilość</label>
                    <input type="text" className="form-control" value={product.amount || ""} readOnly={true} />
                </div>
            </div>)
        });
    }

}
import React from 'react';
import { Link } from "react-router-dom";

export default class Administration extends React.Component {


    render() {

        return (
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active text-dark" href="#!">Komunikaty</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-dark" href="#!">Dokumenty</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-dark" href="#!">Historia</a>
                </li>
            </ul>
            // <ul className="nav nav-tabs">
            //     <li className="nav-item text-dark">
            //         <Link to="/administration/messages" className="nav-link" id="lastDocuments-tab">Komunikaty</Link>
            //     </li>
            // </ul>
            // <div className="d-flex" style={{ minHeight: '93.4vh' }}>
            //     <div className="w-25 list-group user-select-none border">
            //         <Link to="/administration/messages" className="list-group-item list-group-item-action">Komunikaty</Link>
            //     </div>
            //     <div className="p-3 text-center container">

            //     </div>
            // </div>
        )
    }

    componentWillUnmount() {
        const { clearItems, clearItem } = this.props;
        clearItems();
        clearItem();
    }
}
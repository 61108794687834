import React from 'react';
import _ from "lodash";
import ComponentNavbar from '../../application/components/componentNavbar';
import FileDropper from './fileDropper';
import ComponentLoader from '../../application/components/componentLoader';

export default class EnovaImport extends React.Component {

    renderProductsErrors = (products) => {
        if (products) {
            return products.map((prod, i) => {
                const { productId, type, ean, name, amount, code, expirationDate, seriesNr, displayName, unit } = prod;
                return (
                    <div key={i} className="document-import-errors-products">
                        <p> Produkt: <strong> {displayName}</strong></p>
                        <p> {(productId) ? `ID: ${productId}` : null}</p>
                        <p>  {(type) ? `Typ: ${type}` : null}</p>
                        <p>  {(ean) ? `EAN: ${ean}` : null}</p>
                        <p>  {(code) ? `Kod: ${code}` : null}</p>
                        <p> {(name) ? `Nazwa: ${name}` : null}</p>
                        <p> {(amount) ? `Ilość: ${amount}` : null}</p>
                        <p> {(expirationDate) ? `Data ważności: ${expirationDate}` : null}</p>
                        <p> {(seriesNr) ? `Numer serii: ${seriesNr}` : null}</p>
                        <p> {(unit) ? `Jednostka miary: ${unit}` : null}</p>
                    </div>
                )
            })
        }
    }

    renderCompletionErrors = (errors) => {
        return errors.map((error, index) => <p key={`completion-error-${index}`}>{error}</p>);
    }

    renderImportErrors = () => {
        const { errors } = this.props.enovaImport;

        if (errors) {
            return errors.map((doc, i) => {
                const { warehouseFrom, document, products, completionErrors, documentNumber } = doc;
                if (!doc.correct) {
                    return (
                        <div key={i} className="document-import-errors">
                            <div>
                                Nie zaimportowano dokumentu: <strong>{document}</strong>
                                <hr />
                            </div>
                            {warehouseFrom && <div className="document-import-errors-products">
                                <p>Magazyn: <strong>Magazyn z</strong></p>
                                <p>{warehouseFrom}</p>
                            </div>}
                            {documentNumber && <div className="document-import-errors-products">
                                <p>{documentNumber}</p>
                            </div>}
                            {this.renderProductsErrors(products)}
                        </div>
                    )
                }
                if (doc.hasOwnProperty("autoCompleted")) {
                    if (doc.autoCompleted) {
                        return (
                            <div key={i} className="alert alert-success">
                                Dokument <strong>{document}</strong> został zatwierdzony automatycznie.
                            </div>
                        )
                    } else {
                        return (
                            <div key={i} className="alert alert-danger">
                                Dokument <strong>{document}</strong> nie mógł być zatwierdzony automatycznie z powodu błędów i został usunięty z systemu. 
                                <hr />
                                {this.renderCompletionErrors(completionErrors)}
                            </div>
                        )
                    }

                }
                return null;
            });
        }
    }

    renderInfo = () => {
        const { info } = this.props.enovaImport;
        if (!info) {
            return null;
        }

    return <div className="alert alert-info">Id importu: <b>{info.importId}</b><br />Zaimportowano <b>{info.imported}</b> z <b>{info.all}</b> dokumentów. <b>{info.errors}</b> dokumentów nie zostało zaimportowanych z powodu błędów, <b>{info.unsupported}</b> ma <span title={_.join(info.unsupportedTypes, ", ")}>nieobsługiwany typ</span>, <b>{info.existing}</b> zostało pominiętych ponieważ już istnieją w systemie.<br/>Automatycznie zrealizowano <b>{info.autoCompleted.length}</b> dokumentów.</div>
    }

    render() {
        const filedropperStyle = {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '3rem',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            margin: '20px',
            minHeight: '200px'
        };

        const importErrors = this.renderImportErrors();
        const info = this.renderInfo();
        const { isLoading } = this.props.enovaImport;

        const fileDropper = (!isLoading) ?
            <FileDropper
                filedropperStyle={filedropperStyle}
                {...this.props}
            /> :
            <div style={filedropperStyle}>
                <ComponentLoader />
            </div>

        return (
            <div className="d-flex container flex-column">
                <ComponentNavbar
                    title="Import dokumentów Enova"
                />

                {fileDropper}
                {info}
                {importErrors}
            </div>
        )
    }
}
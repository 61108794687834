import React from 'react';
import { connect } from 'react-redux';
import { getRole, clearRole, deleteRole, copyRole, getUsers, assignRole } from '../actions/role.action'
import RoleDetails from '../components/roleDetails.component';

class RoleDetailsContainer extends React.Component {
    render() {
        return (
            <div>
                <RoleDetails {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        role: store.role.role,
        saved: store.role.saved,
        isAdmin: store.auth.isAdmin,
        users: store.role.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getRole: (id) => {
            dispatch(getRole(id));
        },
        clearRole: () => {
            dispatch(clearRole());
        },
        deleteRole: () => {
            dispatch(deleteRole());
        },
        copyRole: (navigation) => {
            dispatch(copyRole(navigation))
        },
        getUsers: () => {
            dispatch(getUsers());
        },
        assignRole: (users) => {
            dispatch(assignRole(users));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleDetailsContainer);
import produce from "immer";
import {
    LOGIN,
    SET_TOKEN,
    IS_AUTH,
    CLEAR
} from '../actions/auth.actions';

const authDefaultState = {
    isAuth: false,
    token: null,
    _id: null,
    company: null,
    dbName: null,
    exp: null,
    lat: null,
    isAdmin: false,
    name: null,
    email: null
}

const authReducer = (state = authDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case CLEAR:{
                return authDefaultState;
            }
            case SET_TOKEN: {
                draft.token = action.token;
                break;
            }
            case IS_AUTH: {
                draft.isAuth = action.isAuth;
                break;
            }
            case LOGIN: {
                draft._id = action._id;
                draft.company = action.company;
                draft.dbName = action.dbName;
                draft.exp = action.exp;
                draft.lat = action.lat;
                draft.isAdmin = action.isAdmin;
                draft.name = action.name;
                draft.email = action.email;
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default authReducer;
import produce from 'immer';

import { SET_WAREHOUSES, SET_FILTERS, RESET_FILTERS, SET_OFFSET } from '../actions/warehouses.action'

const initial_state = {
    warehouses: null,
    total: 0,
    filters: {
        name: "",
        code: ""
    },
    limit: 25,
    offset: 0
}

const generatorListReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_WAREHOUSES:
                draft.warehouses = action.warehouses;
                draft.total = action.total;
                break;
            case SET_FILTERS:
                draft.filters[action.filter] = action.value
                draft.offset = 0;
                break;
            case RESET_FILTERS:
                draft.filters = {
                    name: "",
                    code: ""
                };
                draft.offset = 0;
                break;
            case SET_OFFSET: {
                draft.offset = action.offset;
                break;
            }
            default: return draft;
        }
    })
}

export default generatorListReducer;
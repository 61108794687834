import produce from "immer";
import moment from 'moment';

import {
    GET_DOCUMENTS,
    SET_FILTERS,
    CHANGE_OFFSET,
    RESET_PARAMS,
    SET_ACTIVE_DOCUMENT
} from '../actions/documents.actions';

const documentsDefaultState = {
    documentsPerPage: 50,
    offset: 0,
    total: 0,
    filters: {
        type: '',
        query: '',
        status: undefined,
        dateFrom: moment().subtract(2, 'weeks').startOf('day').toISOString(),
        dateTo: moment().endOf('day').toISOString(),
        warehouseFrom: null,
        warehouseTo: null,
        customer: "",
        product: null,
        inspectionUser: null,
        completionUser: null,
        transportType: undefined,
        inspectionStatus: undefined
    },
    documentList: null,
    activeDocument: null
}

const documentsReducer = (state = documentsDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case GET_DOCUMENTS: {
                draft.documentList = action.documents;
                draft.total = action.total;
                break;
            }
            case SET_FILTERS: {
                draft.filters[action.filter] = action.value;
                draft.offset = 0;
                break;
            }
            case CHANGE_OFFSET: {
                draft.offset = action.offset;
                break;
            }
            case RESET_PARAMS: {
                draft.filters = {
                    type: '',
                    query: '',
                    status: undefined,
                    dateFrom: moment().subtract(2, 'weeks').startOf('day').toISOString(),
                    dateTo: moment().endOf('day').toISOString(),
                    warehouseFrom: null,
                    warehouseTo: null,
                    customer: "",
                    product: null,
                    inspectionUser: null,
                    completionUser: null,
                    transportType: undefined,
                    inspectionStatus: undefined
                };
                draft.offset = 0;
                break;
            }
            case SET_ACTIVE_DOCUMENT: {
                draft.activeDocument = action.activeDocument;
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default documentsReducer;
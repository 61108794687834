import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

import AppContainer from './application/containers/app.container';

import { Provider } from 'react-redux';

import configureStore from './config/configureStore'

export const store = configureStore();

export const browserHistory = createBrowserHistory();

const jsx = (
    <Provider store={store}>
        <AppContainer />
    </Provider>
)

ReactDOM.render(jsx, document.getElementById('root'));
import produce from "immer";

import {
    GET_PRODUCTS,
    CHANGE_OFFSET,
    SET_FILTERS,
    RESET_FILTERS
} from '../actions/products.actions';

const productsDefaultState = {
    productsPerPage: 50,
    offset: 0,
    total: 0,
    filters: {
        name: '',
        ean: '',
        code: '',
        type: undefined,
        PAO: undefined,
        gratis: undefined,
        active: undefined,
        categories: undefined
    },
    productList: null,
}

const productsReducer = (state = productsDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case GET_PRODUCTS: {
                draft.productList = action.products;
                draft.total = action.total;
                break;
            }
            case CHANGE_OFFSET: {
                draft.offset = action.offset;
                break;
            }
            case SET_FILTERS:
                draft.filters[action.filter] = action.value;
                draft.offset = 0;
                break;
            case RESET_FILTERS:
                draft.filters = {
                    name: '',
                    ean: '',
                    code: '',
                    type: undefined,
                    PAO: undefined,
                    gratis: undefined,
                    active: undefined,
                    categories: undefined
                };
                draft.offset = 0;
                break;
            default: {
                return draft;
            }
        }
    });

}

export default productsReducer;
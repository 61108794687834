import Api from '../../services/api';

import { isLoading } from '../../application/actions/application.actions'

const namespace = 'WAREHOUSES';
export const SET_WAREHOUSES = `SET_WAREHOUSES${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;
export const RESET_FILTERS = `RESET_FILTERS_${namespace}`;
export const SET_FILTERS = `SET_FILTERS_${namespace}`;

export const setWarehouses = (warehouses, total) => (dispatch) => {
    dispatch({
        type: SET_WAREHOUSES,
        warehouses: warehouses,
        total: total
    });
}

export const getWarehouses = () => async (dispatch, getState) => {
    const { warehouses: { limit, offset, filters } } = getState();

    dispatch(isLoading(true));

    let params = {
        "sort[createdAt]": 1,
        "limit": limit,
        "offset": offset,
        "select": "name"
    }

    if(filters.name) {
        params["name"] = filters.name;
    }
    if(filters.warehouse) {
        params["_id"] = filters.warehouse._id;
    }

    let res = await Api.get('/warehouse/warehouse', params);

    if (res && res.success) {
        dispatch(isLoading(false));
        dispatch(setWarehouses(res.documents, res.total));
    }
}

export const setFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_FILTERS,
        filter,
        value
    });
}

export const resetFilters = () => dispatch => {
    dispatch({
        type: RESET_FILTERS
    });
}

export const setOffset = (offset) => (dispatch, getState) => {
    dispatch({
        type: SET_OFFSET,
        offset: offset
    });
}
import React from 'react';
import { connect } from 'react-redux';

import Navbar from '../components/navbar.component';

import { logout } from '../../auth/actions/auth.actions';
import { saveShortcut } from '../../dashboard/actions/dashboard.actions';

class NavbarContainer extends React.Component {
    render() {
        return (
            <div>
                <Navbar {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => {
            dispatch(logout())
        },
        saveShortcut: (name, path) => {
            dispatch(saveShortcut(name, path));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarContainer);
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Autocomplete from '../../../application/containers/autocomplete.container';
import ComponentNavbar from '../../../application/components/componentNavbar';
import Pagination from '../../../application/components/pagination';
import Acl from '../../../services/acl.service';
import '../../warehouse.css';

export default class WarehousesComponent extends Component {

    state = {
        offset: 0,
        warehousesPerPage: 50,
        page: 1,
        name: '',
    }

    componentDidMount() {
        const { warehousesPerPage, offset } = this.state;
        const { getWarehouses } = this.props;

        getWarehouses({ limit: warehousesPerPage, offset: offset });
    }

    warehouseSearch = () => {
        const { filters } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-md-5 offset-5">
                        {filters.warehouse && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("warehouse", null)}>
                                    {filters.warehouse.code}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <label>Nazwa</label>
                        <input type="text" className="form-control" placeholder="Wpisz nazwę magazynu" value={filters.name} onChange={(e) => this.changeFilter(e, 'name')} />
                    </div>
                    <div className="col-md-5">
                        <label>Wyszukaj</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("warehouse", value)} content="warehouse" name="warehouseSearch" />
                    </div>
                    <div className="col-md-2 pt-1 text-right">
                        <button className="btn btn-secondary mt-4" onClick={() => this.resetFilters()}>Usuń filtry</button>
                    </div>
                </div>

            </div>);
    }

    changeFilter = (e, name) => {
        const { setFilters, getWarehouses } = this.props;
        let value = e.target.value;
        this.setState({ page: 1 });
        setFilters(name, value);
        getWarehouses();
    }

    changeAutocompleteFilter = (name, value) => {
        const { setFilters, getWarehouses } = this.props;
        this.setState({ page: 1 });
        setFilters(name, value);
        getWarehouses();
    }

    resetFilters = () => {
        const { resetFilters, getWarehouses } = this.props;
        resetFilters();
        this.setState({ page: 1 });
        getWarehouses();
    }

    setPage = (page) => {
        this.setState({ page: page });
    }

    renderPagination = () => {
        const { total, limit, offset, getWarehouses, setOffset } = this.props;
        const { page } = this.state;

        return (
            <Pagination
                getDocuments={getWarehouses}
                changeOffset={setOffset}
                offset={offset}
                total={total}
                perPage={limit}
                setPage={this.setPage}
                page={page}
            />
        )
    }

    renderItems = () => {
        let { warehouses } = this.props;
        const pagination = this.renderPagination();

        if (warehouses && warehouses.length > 0) {
            let cards = warehouses.map((warehouse, index) => {
                return <Link key={index} to={`/warehouses/details/${warehouse._id}`} className="flex-fill list-group-item list-group-item-action">{warehouse.name}</Link>
            });
            return (<ul className="list-group document-list mb-5">
                {pagination}
                {cards}
                {pagination}
            </ul>);
        } else {
            return (
                <ul className="d-flex justify-content-center mt-5">
                    <div>Brak magazynów</div>
                </ul>
            )
        }
    }

    handleAddWarehouse = () => {
        const { history } = this.props;
        history.push('/warehouses/warehouse')
    }

    navbarButton = () => {
        const { auth } = this.props;
        const permission = Acl.isAllowedAction('warehouse', 'warehouse', 'create', auth.isAdmin);

        if (permission) return (<button
            type="button"
            className="btn btn-outline-primary"
            onClick={this.handleAddWarehouse}>Dodaj</button>
        )

    }

    render() {
        const items = this.renderItems();

        return (
            <div className="d-flex flex-column container">
                <ComponentNavbar
                    title="Lista magazynów"
                    button={this.navbarButton()}
                />
                {this.warehouseSearch()}
                {items}
            </div>
        )
    }

}
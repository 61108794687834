import Api from '../../services/api';
import { toast } from 'react-toastify';

import { isLoading } from '../../application/actions/application.actions'

const namespace = 'ROLES';
export const SET_ROLES = `SET_ROLES_${namespace}`;
export const CLEAR_ROLES = `CLEAR_ROLES_${namespace}`;
export const RESET_FILTERS = `RESET_FILTERS_${namespace}`;
export const SET_FILTERS = `SET_FILTERS_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;

export const setRoles = (roles, total) => (dispatch) => {
    dispatch({
        type: SET_ROLES,
        roles: roles,
        total: total
    });
}

export const clearRoles = () => (dispatch) => {
    dispatch({
        type: CLEAR_ROLES
    });
}

export const getRoles = () => async (dispatch, getState) => {
    const { roles: { filters, limit, offset } } = getState();
    dispatch(isLoading(true));

    let params = {
        "sort[name]": 1,
        "limit": limit,
        "offset": offset
    }
    if (filters.query) {
        params.name = filters.query;
    }

    let res = await Api.get("/application/role", params);

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setRoles(res.documents, res.total));
    } else {
        toast('Wystąpił błąd');
    }
}

export const setFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_FILTERS,
        filter,
        value
    });
}

export const resetFilters = () => dispatch => {
    dispatch({
        type: RESET_FILTERS
    });
}

export const setOffset = (offset) => (dispatch, getState) => {
    dispatch({
        type: SET_OFFSET,
        offset: offset
    });
}
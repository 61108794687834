import React, { Component, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Acl from "../../../services/acl.service";
import WarehouseToPrintComponent from './warehouseToPrint.component';
import '../../warehouse.css'

const Code = ({ barcode, positionCode, handleOnClick, positionId, positionDetailsClick, isAdmin }) => {
    const componentRef = useRef();
    let canDelete = Acl.isAllowedAction("warehouse", "position", "delete", isAdmin);
    return (
        <div className="details">
            <div onClick={() => positionDetailsClick(positionId)} >
                <WarehouseToPrintComponent
                    barcode={barcode} positionCode={positionCode}
                    ref={componentRef} />
            </div>
            <div className="position-list-record-print-button">
                <div>
                    <ReactToPrint
                        trigger={() => <button className="btn btn-outline-primary">Drukuj</button>}
                        content={() => componentRef.current} />
                </div>
                <br />
                {canDelete && <div>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleOnClick}> Usuń </button>
                </div>}
            </div>
        </div>
    );
};

export default class WarehousePositionsRecordComponent extends Component {

    render() {
        const { isAdmin } = this.props;

        return (
            <div>
                <div className="col-md-12 position-list-record-info">
                    <div className="info" onClick={() => this.props.positionDetailsClick(this.props.positionId)}>
                        KOD MAGAZYNU: [{this.props.warehouseCode}] <br />
                        KOD POZYCJI: [{this.props.positionCode}] <br />
                        TYP POZYCJI: [{this.props.type}] <br />
                    </div>
                    <div className="barcode">
                        <Code
                            barcode={this.props.barcode}
                            positionCode={this.props.positionCode}
                            handleOnClick={this.props.handleOnClick}
                            positionId={this.props.positionId}
                            positionDetailsClick={(positionId) => this.props.positionDetailsClick(positionId)}
                            isAdmin={isAdmin} />
                    </div>
                </div>
            </div>
        )
    }
}

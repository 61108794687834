import React from 'react';
import { connect } from 'react-redux';
import { changeOffset, getProducts, resetFilters, setFilters } from '../actions/products.actions';
import Products from '../components/products.component';
import '../products.css';

class ProductsContainer extends React.Component {
    render() {
        return (
            <div>
                <Products {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        products: store.products
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProducts: () => {
            dispatch(getProducts())
        },
        changeOffset: (value) => {
            dispatch(changeOffset(value))
        },
        setFilters: (filter, value) => {
            dispatch(setFilters(filter, value))
        },
        resetFilters: () => {
            dispatch(resetFilters());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer);
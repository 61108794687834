import React from 'react';
import { connect } from 'react-redux';
import { getUser, clearUser, deleteUser, changePassword, unlockAccount, changeIsActive } from '../actions/user.action'
import UserDetails from '../components/userDetails.component';

class UserDetailsContainer extends React.Component {
    render() {
        return (
            <div>
                <UserDetails {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        user: store.user.user,
        isAdmin: store.auth.isAdmin
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUser: (id) => {
            dispatch(getUser(id));
        },
        clearUser: () => {
            dispatch(clearUser());
        },
        deleteUser: (history) => {
            dispatch(deleteUser(history));
        },
        changePassword: (newPassword) => {
            dispatch(changePassword(newPassword));
        },
        unlockAccount: () => {
            dispatch(unlockAccount());
        },
        changeIsActive: (isActive) => {
            dispatch(changeIsActive(isActive));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsContainer);
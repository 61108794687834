import produce from 'immer';

import { SET_OPTIONS, CLEAR_OPTIONS } from '../actions/autocomplete.actions'

const initial_state = {
    options: {}
}

const autocompleteReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_OPTIONS:
                draft.options = action.options
                break;
            case CLEAR_OPTIONS:
                draft.options = {};
                break;
            default: return draft;
        }
    })
}

export default autocompleteReducer;
const permissions = [
    //Dokumenty
    {
        actions: [
            { action: "get", label: "Wyświetlanie", info: "Widoczność modułu, listy i szczegółów" },
            { action: "delete", label: "Usuwanie", info: "Widoczność przycisku 'Usuń' w szczegółach dokumentu" },
            { action: "changeStatus", label: "Zmiana statusu", info: "Możliwość rozpoczęcia kompletacji w aplikacji mobilnej" },
            { action: "saveCompleted", label: "Zapis kompletacji", info: "Zapis dokumentu po zakończeniu kompletacji w aplikacji mobilnej" },
            { action: "createZp", label: "Tworzenie ZP", info: "Widoczność przycisku 'ZP' w aplikacji mobilnej" },
            { action: "generateDocumentCodes", label: "Generowanie kodów z dokumentu", info: "Widoczność przycisku 'Drukuj kody' w szczegółach dokumentu" },
            { action: "undoDocument", label: "Wycofanie realizacji dokumentów", info: "Możliwość cofania realizacji dokumentów" },
            { action: "quickEdit", label: "Szybka Edycja", info: "Możliwość szybkiej edycji dokumentu niezrealizowanego" },
            { action: "advancedEdit", label: "Zaawansowana edycja", info: "Możliwość zaawansowanej edycji dokumentu niezrealizowanego" },
            { action: "manualCompletion", label: "Manualna kompletacja", info: "Możliwość manualnej kompletacji dokumentu" }
        ],
        module: "document",
        controller: "document",
        label: "Dokumenty"
    },
    //Sprawdzanie dokumentów
    {
        actions: [
            { action: "changeInspectionStatus", label: "Zmiana statusu", info: "Widoczność przycisku przełączenia na listę dokumentów do sprawdzenia w aplikacji mobilnej" },
            { action: "setProductInspected", label: "Sprawdzanie produktów", info: "Możliwość zmiany flagi 'sprawdzony' w produktach / gratisach dokumentu w aplikacji mobilnej" },
            { action: "setDocumentInspected", label: "Zatwierdzanie dokumentu", info: "Możliwość zatwierdzenia sprawdzenia dokumentu w aplikacji mobilnej" }
        ],
        module: "document",
        controller: "inspection",
        label: "Sprawdzanie dokumentów"
    },
    //Import dokumentów
    {
        actions: [
            { action: "enovaImport", label: "Import z Enovy", info: "Możliwość importu dokumentów z Enovy w formacie XML" },
            { action: "autoCompletion", label: "Automatyczne zatwierdzanie dokumentów", info: "Możliwość automatycznego zatwierdzania dokumentów podczas importu" }
        ],
        module: "document",
        controller: "import",
        label: "Import dokumentów"
    },
    //Rezerwacje
    {
        actions: [
            { action: "get", label: "Pobieranie", info: "Możliwość zaczytania rezultatów po wznowieniu realizacji dokumentu w aplikacji mobilnej. Możliwość wyświetlenia zakładki rezerwacji w szczegółach dokumentu w aplikacji webowej" },
            { action: "create", label: "Tworzenie", info: "Możliwość zapisu realizacji pojedynczego produktu w aplikacji mobilnej" },
            { action: "update", label: "Edycja", info: "Możliwość edycji realizacji pojedynczego produktu w aplikacji mobilnej" },
            { action: "delete", label: "Usuwanie", info: "Możliwość usunięcia realizacji pojedynczego produktu w aplikacji mobilnej" }
        ],
        module: "document",
        controller: "reservation",
        label: "Rezerwacje"
    },
    //Generator kodów
    {
        actions: [
            { action: "get", label: "Wyświetlanie", info: "Widoczność modułu, lista kodów" },
            { action: "create", label: "Tworzenie", info: "Widoczność przycisku 'Dodaj' na liście kodów" },
            { action: "update", label: "Edycja", info: "Możliwość edycji dat ważności kodów" },
            { action: "delete", label: "Usuwanie", info: "Widoczność przycisku 'Usuń' na liście kodów" }
        ],
        module: "generator",
        controller: "generator",
        label: "Generator kodów"
    },
    //Produkty
    {
        actions: [
            { action: "get", label: "Wyświetlanie", info: "Widoczność modułu, lista i szczegóły produktów" },
            { action: "create", label: "Tworzenie", info: "Widoczność przycisku 'Dodaj' na liście produktów" },
            { action: "update", label: "Edycja", info: "Widoczność przycisku 'Edytuj' w szczegółach produktu" },
            { action: "delete", label: "Usuwanie", info: "Widoczność przycisku 'Usuń' w szczegółach produktu" },
            { action: "getProductPositions", label: "Pobieranie stanów produktu", info: "Widoczność stanów produktu w zakładce 'Stany magazynowe' w szczegółach produktu" },
            { action: "getProductReservations", label: "Pobieranie rezerwacji produktu", info: "Widoczność zakładki 'Rezerwacje' w szczegółach produktu" }
        ],
        module: "product",
        controller: "product",
        label: "Produkty"
    },
    //Kategorie produktowe
    {
        actions: [
            { action: "get", label: "Pobieranie drzewa kategorii", info: "Możliwość wyświetlania drzewa kategorii" },
            { action: "update", label: "Tworzenie", info: "Możliwość edycji kategorii" }
        ],
        module: "product",
        controller: "category",
        label: "Kategorie produktowe"
    },
    //Magazyny
    {
        actions: [
            { action: "get", label: "Wyświetlanie", info: "Widoczność modułu, lista i szczegóły magazynów" },
            { action: "create", label: "Tworzenie", info: "Widoczność przycisku 'Dodaj' na liście magazynów" },
            { action: "getWarehouseStatistics", label: "Wyświetlanie statystyk", info: "Widoczność zakładki 'Statystyki' w szczegółach magazynu" }
        ],
        module: "warehouse",
        controller: "warehouse",
        label: "Magazyny"
    },
    {
        actions: [
            { action: "get", label: "Wyświetlanie", info: "Widoczność pozycji i ich szczegółów w szczegółach magazynu" },
            { action: "create", label: "Tworzenie", info: "Widoczność przycisku 'Dodaj' w szczegółach magazynu" },
            { action: "update", label: "Edycja", info: "Możliwość edycji produktów i typu pozycji" },
            { action: "delete", label: "Usuwanie", info: "Widoczność przycisku 'Usuń' przy pozycji w szczegółach magazynu" },
            { action: "checkExists", label: "Sprawdzenie stanu", info: "Możliwość skanowania pozycji i sprawdzania jej stanu w aplikacji mobilnej" }
        ],
        module: "warehouse",
        controller: "position",
        label: "Pozycje magazynowe"
    },
    //Inwentaryzacja
    {
        actions: [
            { action: "get", label: "Wyświetlanie", info: "Widoczność modułu, lista i szczegóły inwentaryzacji w aplikacj webowej. Wymagane do robienia inwentaryzacji w aplikacji mobilnej" },
            { action: "create", label: "Tworzenie", info: "Widoczność przycisku 'Dodaj' na liście inwentaryzacji" },
            { action: "update", label: "Zamykanie inwentaryzacji", info: "Widoczność przycisku 'Zamknij inwentaryzację'" },
            // { action: "delete", label: "Usuwanie" },
            { action: "updateInventoryPosition", label: "Odczyt pozycji", info: "Możliwość odczytu pozycji w trakcie inwentaryzacji" },
            { action: "resetInventoryPosition", label: "Usuwanie odczytu pozycji", info: "Widoczność przycisku 'Usuń' przy odczycie w szczegółach inwentaryzacji" },
            { action: "allowInventoryEdit", label: "Zezwalanie na edycję odczytu", info: "Widoczność przycisku 'Zezwól na edycję' przy odczycie w szczegółach inwentaryzacji" },
            { action: "generateReport", label: "Pobieranie raportu", info: "Widoczność przycisku 'Generuj raport' szczegółach inwentaryzacji" }
        ],
        module: "warehouse",
        controller: "inventory",
        label: "Inwentaryzacja"
    },
    //Postęp inwentaryzacji
    {
        actions: [
            { action: "get", label: "Wyświetlanie", info: "Możliwość wyświetlenia listy aktualnie inwentaryzowanych pozycji w szczegółach inwentaryzacji" },
            { action: "create", label: "Zapis postępu", info: "Możliwość zapisu postępu inwentaryzacji na pozycji" },
        ],
        module: "warehouse",
        controller: "inventoryProgress",
        label: "Postęp inwentaryzacji"
    },
    //Historia pozycji
    {
        actions: [
            { action: "get", label: "Wyświetlanie", info: "Widoczność zakładki historia w szczegółach produktu, pozycji magazynowej i inwentaryzacji" },
        ],
        module: "warehouse",
        controller: "positionHistory",
        label: "Historia pozycji"
    },
    //Raporty
    {
        actions: [
            { action: "create", label: "Tworzenie", info: "Widoczność modułu, możliwość pobierania raportów" },
        ],
        module: "statistic",
        controller: "report",
        label: "Raporty"
    },
    //Użytkownicy
    {
        actions: [
            { action: "get", label: "Wyświetlanie", info: "Widoczność modułu, lista i szczegóły użytkowników" }
        ],
        module: "application",
        controller: "user",
        label: "Użytkownicy"
    }
]

export default permissions;


import React from "react";

export default class DictionaryForm extends React.Component {

    render() {
        const { item, deleteValue, setValue } = this.props;
        if (!item) {
            return null;
        }
        let inputs = item.values.map((obj, index) => {
            return (
                <div className="row" key={index}>
                    <div className="col-6">
                        <label>Nazwa</label>
                        <input type="text" disabled={obj.protected} className="form-control" placeholder="Wpisz nazwę" value={obj.name} onChange={(e) => setValue(e, 'name', index)} />
                    </div>
                    <div className="col-4">
                        <label>Wartość</label>
                        <input type="text" disabled={obj.protected} className="form-control" placeholder="Wpisz wartość" value={obj.value} onChange={(e) => setValue(e, 'value', index)} />
                    </div>
                    {!obj.protected && <div className="col-2 pt-1">
                        <button className="btn btn-danger mt-4" onClick={() => { deleteValue(index) }}>Usuń</button>
                    </div>}
                </div>
            );
        });

        return (<div>
            {inputs}
        </div>);
    }
}

import React, { Component } from 'react';
import ComponentNavbar from '../../application/components/componentNavbar';
import Autocomplete from '../../application/containers/autocomplete.container';
import AclManager from '../../services/acl.service';
import DictManager from '../../services/dict.service';
import moment from "moment";

export default class Report extends Component {

    getReportOptions = () => {
        const { report, errors } = this.props;

        switch (report.type) {
            case "1": {
                return (
                    <div className="row">
                        <div className="col-lg-6">
                            <label>Magazyn</label>
                            {report.warehouse && (
                                <h6>
                                    <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.setFieldValue("warehouse", null)}>
                                        {report.warehouse}
                                        {"  "}
                                        <button className="btn btn-sm btn-secondary">X</button>
                                    </span>
                                </h6>
                            )}
                            <Autocomplete onSelect={(value) => this.setFieldValue("warehouse", value.code)} content="warehouse" name="reportWarehouse" />
                            {errors.warehouse && <p className="text-danger">{errors.warehouse}</p>}
                        </div>
                        <div className="col-lg-6">
                            <label>Uwzględnij zaimportowane dokumenty</label>
                            <select name="includeImported" className="form-control" value={report.includeImported || false} onChange={(e) => this.changeField("includeImported", e)}>
                                <option value={false}>Nie</option>
                                <option value={true}>Tak</option>
                            </select>
                            {errors.includeImported && <p className="text-danger">{errors.includeImported}</p>}
                        </div>
                    </div>);
            }
            case "2":
            case "4": {
                return (
                    <div className="row">
                        <div className="col-lg-6">
                            <label>Magazyn</label>
                            {report.warehouse && (
                                <h6>
                                    <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.setFieldValue("warehouse", null)}>
                                        {report.warehouse}
                                        {"  "}
                                        <button className="btn btn-sm btn-secondary">X</button>
                                    </span>
                                </h6>
                            )}
                            <Autocomplete onSelect={(value) => this.setFieldValue("warehouse", value.code)} content="warehouse" name="reportWarehouse" />
                            {errors.warehouse && <p className="text-danger">{errors.warehouse}</p>}
                        </div>
                    </div>);
            }
            case "3": {
                return (
                    <div className="row">
                        <div className="col-lg-6">
                            <label>Magazyn</label>
                            {report.warehouse && (
                                <h6>
                                    <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.setFieldValue("warehouse", null)}>
                                        {report.warehouse}
                                        {"  "}
                                        <button className="btn btn-sm btn-secondary">X</button>
                                    </span>
                                </h6>
                            )}
                            <Autocomplete onSelect={(value) => this.setFieldValue("warehouse", value.code)} content="warehouse" name="reportWarehouse" />
                            {errors.warehouse && <p className="text-danger">{errors.warehouse}</p>}
                        </div>
                        <div className="col-lg-3">
                            <label>Data (od)</label>
                            <input type="date" className="form-control" value={moment(report.dateFrom).format("YYYY-MM-DD")} onChange={(e) => this.changeField('dateFrom', e)} />
                        </div>
                        <div className="col-md-3">
                            <label>Data (do)</label>
                            <input type="date" className="form-control" value={moment(report.dateTo).format("YYYY-MM-DD")} onChange={(e) => this.changeField('dateTo', e)} />
                        </div>
                    </div>);
            }
            case "5": {
                return (
                    <div className="row">
                        <div className="col-lg-6">
                            <label>Magazyn</label>
                            {report.warehouse && (
                                <h6>
                                    <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.setFieldValue("warehouse", null)}>
                                        {report.warehouse.name}
                                        {"  "}
                                        <button className="btn btn-sm btn-secondary">X</button>
                                    </span>
                                </h6>
                            )}
                            <Autocomplete onSelect={(value) => this.setFieldValue("warehouse", value)} content="warehouse" name="reportWarehouse" />
                            {errors.warehouse && <p className="text-danger">{errors.warehouse}</p>}
                        </div>
                        <div className="col-lg-6">
                            <label>Produkt</label>
                            {report.product && (
                                <h6>
                                    <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.setFieldValue("product", null)}>
                                        {report.product.name}
                                        {"  "}
                                        <button className="btn btn-sm btn-secondary">X</button>
                                    </span>
                                </h6>
                            )}
                            <Autocomplete onSelect={(value) => this.setFieldValue("product", value)} content="product" name="reportProduct" />
                            {errors.product && <p className="text-danger">{errors.product}</p>}
                        </div>
                        <div className="col-lg-6">
                            <label>Numer serii</label>
                            <input className="form-control" onChange={(e) => this.setFieldValue("seriesNr", e.target.value)} value={report.seriesNr || ""} placeholder="Wpisz numer serii" />
                            {errors.seriesNr && <p className="text-danger">{errors.seriesNr}</p>}
                        </div>
                    </div>);
            }
            case "6":
            case "8":
                return null;
            case "7":
                return (
                    <div className="row">
                        <div className="col-lg-6">
                            <label>Magazyn</label>
                            {report.warehouse && (
                                <h6>
                                    <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.setFieldValue("warehouse", null)}>
                                        {report.warehouse}
                                        {"  "}
                                        <button className="btn btn-sm btn-secondary">X</button>
                                    </span>
                                </h6>
                            )}
                            <Autocomplete onSelect={(value) => this.setFieldValue("warehouse", value.code)} content="warehouse" name="reportWarehouse" />
                            {errors.warehouse && <p className="text-danger">{errors.warehouse}</p>}
                        </div>
                        <div className="col-lg-6">
                            <label>Typ dokumentów</label>
                            <select className="form-control" value={report.documentType || "0"} onChange={(e) => this.changeField('documentType', e)}>
                                {DictManager.getOptionsByType(4, "importType", "importType")}
                            </select>
                        </div>
                        <div className="col-lg-6">
                            <label>Data (od)</label>
                            <input type="date" className="form-control" value={moment(report.dateFrom).format("YYYY-MM-DD")} onChange={(e) => this.changeField('dateFrom', e)} />
                        </div>
                        <div className="col-md-6">
                            <label>Data (do)</label>
                            <input type="date" className="form-control" value={moment(report.dateTo).format("YYYY-MM-DD")} onChange={(e) => this.changeField('dateTo', e)} />
                        </div>
                    </div>);
            default: {
                return null;
            }
        }
    }

    setFieldValue = (name, value) => {
        const { changeField } = this.props;
        changeField(name, value);
    }

    changeField = (name, e) => {
        const { changeField, setReport } = this.props;
        let value = e.target.value;
        if (name === "type") {
            switch (value) {
                case "1": {
                    setReport({
                        type: value,
                        includeImported: true
                    });
                    break;
                }
                case "2":
                case "4":
                case "5":
                case "7":
                case "8": {
                    setReport({
                        type: value
                    });
                    break;
                }
                case "3":
                case "6": {
                    setReport({
                        type: value,
                        dateFrom: moment().startOf('quarter').toISOString(),
                        dateTo: moment().endOf('quarter').toISOString()
                    });
                    break;
                }
                default: {
                    setReport({});
                    break;
                }
            }
        } else if (name === "dateFrom") {
            if (value === "") {
                value = moment().startOf('quarter').toISOString();
            } else {
                value = moment(value).startOf('day').toISOString();
            }
            changeField(name, value);
        } else if (name === "dateTo") {
            if (value === "") {
                value = moment().endOf('quarter').toISOString()
            } else {
                value = moment(value).endOf('day').toISOString();
            }
            changeField(name, value);
        } else {
            changeField(name, value);
        }
    }

    renderForm = () => {
        const { report, errors, isAdmin } = this.props;
        let options = this.getReportOptions();
        let visible = {};
        let permission = AclManager.getPermission("statistic", "report");
        if (permission && permission.filters && permission.filters.types) {
            permission.filters.types.forEach((type) => visible[type] = true);
        }
        return (
            <div>
                <div className="row mt-5">
                    <div className="col-lg-12">
                        <form>
                            <div className="form-group">
                                <label>Typ raportu</label>
                                <select name="type" className="form-control" value={report.type || "0"} onChange={(e) => this.changeField("type", e)}>
                                    <option value="0">Wybierz</option>
                                    {(isAdmin || visible[1]) && <option value="1">Stan magazynowy produktów</option>}
                                    {(isAdmin || visible[2]) && <option value="2">Terminy ważności produktów</option>}
                                    {(isAdmin || visible[3]) && <option value="3">Czasy realizacji dokumentów</option>}
                                    {(isAdmin || visible[4]) && <option value="4">Stan magazynowy produktów z seriami</option>}
                                    {(isAdmin || visible[5]) && <option value="5">Raport GMP+</option>}
                                    {(isAdmin || visible[6]) && <option value="6">Eksport produktów</option>}
                                    {(isAdmin || visible[7]) && <option value="7">Produkty z dokumentów</option>}
                                    {(isAdmin || visible[8]) && <option value="8">Dostępność produktów</option>}
                                </select>
                                {errors.name && <p className="text-danger">{errors.name}</p>}
                            </div>
                        </form>
                    </div>
                </div>
                {options}
            </div>


        );
    }

    generateReport = () => {
        const { generateReport } = this.props;
        generateReport();
    }

    render() {
        const form = this.renderForm();

        return (
            <div className="d-flex flex-column container">
                <ComponentNavbar
                    title={"Generowanie raportu"}
                    button={null}
                />
                {form}
                <div className="justify-content-center mb-5 mt-5 text-center">
                    <button className="btn btn-primary" type="button" onClick={() => { this.generateReport() }}>Generuj</button>
                </div>
            </div>
        );
    }
}
import produce from 'immer';

import { SET_REPORT, CLEAR_REPORT } from '../actions/report.actions'

const initial_state = {
    report: {},
    errors: {}
}

const reportReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_REPORT:
                draft.report = action.report;
                break;
            case CLEAR_REPORT:
                draft.report = {};
                break;
            default: return draft;
        }
    })
}

export default reportReducer;
import produce from 'immer';
import { CLEAR_CODE, CLEAR_ERRORS, SET_CODE, SET_ERRORS, SET_SAVED } from '../actions/generator.code.actions';

const initial_state = {
    isUpdated: false,
    code: {
        expirationDate: "",
        eanNr: '',
        seriesNr: ''
    },
    saved: false,
    errors: {},
}

const generatorCodeReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_CODE:
                draft.code = action.code;
                break;
            case SET_ERRORS:
                draft.errors = action.errors;
                break;
            case CLEAR_ERRORS:
                draft.errors = {};
                break;
            case CLEAR_CODE:
                draft.code = {
                    expirationDate: "",
                    eanNr: '',
                    seriesNr: ''
                };
                draft.saved = false;
                break;
            case SET_SAVED: {
                draft.saved = true;
                break;
            }
            default: return draft;
        }
    })
}

export default generatorCodeReducer
import produce from "immer";

import {
    SET_ERRORS,
    CLEAR_ERRORS
} from '../actions/enovaImport.actions';

const documentsDefaultState = {
    errors: null,
    info: null
}

const enovaImportReducer = (state = documentsDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_ERRORS: {
                draft.errors = action.errors;
                draft.info = action.info;
                break;
            }
            case CLEAR_ERRORS: {
                draft.errors = null;
                draft.info = null;
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default enovaImportReducer;
import Api from '../../services/api';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { isLoading } from '../../application/actions/application.actions'

const namespace = 'ADMINISTRATION';
export const SET_ITEMS = `SET_ITEMS_${namespace}`;
export const CLEAR_ITEMS = `CLEAR_ITEMS_${namespace}`;
export const SET_ITEM = `SET_ITEM_${namespace}`;
export const CLEAR_ITEM = `CLEAR_ITEM_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;

export const setItems = (items, total) => (dispatch) => {
    dispatch({
        type: SET_ITEMS,
        items: items,
        total: total
    });
}

export const clearItems = () => (dispatch) => {
    dispatch({
        type: CLEAR_ITEMS
    });
}

export const setItem = (item) => (dispatch) => {
    dispatch({
        type: SET_ITEM,
        item: item
    });
}

export const clearItem = () => (dispatch) => {
    dispatch({
        type: CLEAR_ITEM
    });
}

export const setOffset = (offset) => (dispatch, getState) => {
    dispatch({
        type: SET_OFFSET,
        offset: offset
    });
}

export const getMessages = () => async (dispatch) => {

    dispatch(isLoading(true));

    let res = await Api.get("/application/message");

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setItems(res.document, res.total));
    } else {
        toast('Wystąpił błąd');
    }
}

import Api from '../../services/api';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { isLoading } from '../../application/actions/application.actions'

const namespace = 'USER';
export const SET_USER = `SET_USER_${namespace}`;
export const CLEAR_USER = `CLEAR_USER_${namespace}`;
export const SET_SAVED = `SET_SAVED_${namespace}`;
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${namespace}`;

export const setUser = (user) => (dispatch) => {
    dispatch({
        type: SET_USER,
        user: user
    });
}

export const clearUser = () => (dispatch) => {
    dispatch({
        type: CLEAR_USER
    });
}

export const getUser = (id) => async (dispatch) => {
    dispatch(isLoading(true));

    let res = await Api.get(`/application/user/${id}`);

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setUser(res.document));
    } else {
        toast('Wystąpił błąd');
    }
}

export const deleteUser = (history) => async (dispatch, getState) => {
    const { user: { user } } = getState();

    dispatch(isLoading(true));

    let res = await Api.delete(`/application/user/${user._id}`);

    dispatch(isLoading(false));

    if (res && res.success) {
        toast('Usunięto');
        dispatch({
            type: SET_SAVED
        });
        history.push("/users");
    } else {
        toast('Wystąpił błąd');
    }
}

export const saveUser = () => async (dispatch, getState) => {
    const { user: { user } } = getState();

    dispatch(clearErrors());

    let data = Object.assign({}, user);

    if (data.role && _.isObject(data.role)) {
        data.role = data.role._id;
    }

    _.each(data, (value, key) => {
        if (_.isNil(value)) {
            delete data[key];
        }
    })

    dispatch(isLoading(true));

    let res = null;

    if (user._id) {
        res = await Api.put(`/application/user/${user._id}`, data);
    } else {
        res = await Api.post("/application/user", data);
    }
    dispatch(isLoading(false));

    if (res && res.success) {
        toast('Zapisano');
        dispatch({
            type: SET_SAVED
        });
    } else {
        dispatch(setErrors(res.err || {}));
        toast('Wystąpił błąd');
    }
}

export const changeField = (name, value) => (dispatch, getState) => {
    const { user: { user } } = getState();

    let newUser = Object.assign({}, user);
    newUser[name] = value;
    dispatch(setUser(newUser));
}

export const setErrors = (errors) => (dispatch) => {
    dispatch({
        type: SET_ERRORS,
        errors: errors
    });
}


export const clearErrors = (errors) => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    });
}


export const changePassword = (newPassword) => async (dispatch, getState) => {
    const { user: { user } } = getState();

    dispatch(isLoading(true));

    let res = await Api.put(`/application/user/changePassword/${user._id}`, { password: newPassword });

    if (res && res.success) {
        toast('Hasło zostało zmienione');
    } else {
        toast('Wystąpił błąd');
    }

    dispatch(isLoading(false));
}

export const unlockAccount = () => async (dispatch, getState) => {
    const { user: { user } } = getState();

    dispatch(isLoading(true));

    let res = await Api.put(`/application/user/unlockAccount/${user._id}`, {});

    if (res && res.success) {
        dispatch(changeField("failedLogins", 0));
        toast('Zapisano');
    } else {
        toast('Wystąpił błąd');
    }

    dispatch(isLoading(false));
}


export const changeIsActive = (isActive) => async (dispatch, getState) => {
    const { user: { user } } = getState();

    dispatch(isLoading(true));

    let res = await Api.put(`/application/user/changeIsActive/${user._id}`, { active: isActive });

    if (res && res.success) {
        dispatch(changeField("active", isActive));
        toast('Zapisano');
    } else {
        toast('Wystąpił błąd');
    }

    dispatch(isLoading(false));
}


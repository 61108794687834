import { toast } from 'react-toastify';
import { isLoading } from '../../application/actions/application.actions';
import Api from '../../services/api';

const namespace = 'WAREHOUSE';
export const IS_UPDATED = `IS_UPDATED_${namespace}`;
export const SET_WAREHOUSE = `SET_WAREHOUSE_${namespace}`;
export const CLEAR_WAREHOUSE = `CLEAR_WAREHOUSE_${namespace}`;
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${namespace}`;
export const RESET_FILTERS = `RESET_FILTERS_${namespace}`;
export const SET_FILTERS = `SET_FILTERS_${namespace}`;
export const SET_POSITIONS = `SET_POSITIONS_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;
export const SET_STATISTICS = `SET_STATISTICS_${namespace}`;
export const CLEAR_STATISTICS = `CLEAR_STATISTICS_${namespace}`;

export const setIsUpdated = (data) => (dispatch) => {
    dispatch({
        type: IS_UPDATED,
        isUpdated: data
    });
}

export const setWarehouse = (data) => (dispatch) => {
    dispatch({
        type: SET_WAREHOUSE,
        warehouse: data
    });
}

export const setErrors = (errors) => (dispatch) => {
    dispatch({
        type: SET_ERRORS,
        errors: errors
    });
}

export const clearWarehouse = () => (dispatch) => {
    dispatch({
        type: CLEAR_WAREHOUSE
    });
}

export const clearErrors = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    });
}

export const setPositions = (positions, total) => (dispatch) => {
    dispatch({
        type: SET_POSITIONS,
        positions: positions,
        total: total
    });
}

export const saveWarehouse = (data) => async (dispatch, getState) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.post('/warehouse/warehouse', data);
        dispatch(isLoading(false));
        if (res.success) {
            toast('Zapisano magazyn')
            dispatch(setIsUpdated(true))
        } else {
            toast('Wystąpił błąd');
            dispatch(setErrors(res.err))
        }
    } catch (err) {
        dispatch(isLoading(false));
    }
}

export const getWarehouse = (warehouseId) => async (dispatch, getState) => {
    const { warehouse: { limit } } = getState();
    dispatch(isLoading(true));
    let res = await Api.get(`/warehouse/warehouse/${warehouseId}`, { limit: limit });

    if (res && res.success) {
        dispatch(isLoading(false));
        if (res.document) {
            let warehouseDocument = res.document;
            dispatch(setWarehouse(warehouseDocument));

            dispatch(getWarehousePositions(warehouseDocument.code));
        } else {
            toast('Brak zasobu');
        }
    }
}

export const getWarehousePositions = (warehouseCode) => async (dispatch, getState) => {
    try {
        const { warehouse: { filters, limit, offset } } = getState();

        dispatch(isLoading(true));

        let params = {
            "warehouseCode": warehouseCode,
            "limit": limit,
            "offset": offset
        }

        if (filters.query) {
            params.code = filters.query
        }

        if (filters.type) {
            params.type = filters.type
        }

        if (filters.product) {
            params["products.ean"] = filters.product.ean;
        }

        let res = await Api.get("/warehouse/position", params);

        dispatch(isLoading(false));

        if (res && res.success) {
            dispatch({
                type: SET_POSITIONS,
                positions: res.documents,
                total: res.total
            });
        } else {
            toast("Wystąpił błąd");
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast("Wystąpił błąd");
    }
}

export const deleteWarehousePosition = (positionId) => async (dispatch, getState) => {
    try {
        const { warehouse: { warehouse } } = getState();

        dispatch(isLoading(true));

        let res = await Api.delete(`/warehouse/position/${positionId}`);

        dispatch(isLoading(false));

        if (res.success) {
            toast('Usunięto pozycję magazynową');
            dispatch(getWarehousePositions(warehouse.code));
        } else {
            toast('Wystąpił błąd');
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const setFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_FILTERS,
        filter,
        value
    });
}

export const resetFilters = () => dispatch => {
    dispatch({
        type: RESET_FILTERS
    });
}

export const setOffset = (offset) => (dispatch, getState) => {
    dispatch({
        type: SET_OFFSET,
        offset: offset
    });
}

export const getWarehouseStatistics = () => async (dispatch, getState) => {
    const { warehouse: { warehouse } } = getState();

    dispatch(isLoading(true));

    let res = await Api.get(`/warehouse/warehouse/getWarehouseStatistics/${warehouse._id}`);

    dispatch(isLoading(false));

    if (res.success) {
        dispatch(setWarehouseStatistics(res.document));
    } else {
        toast('Wystąpił błąd');
    }
}

export const setWarehouseStatistics = (statistics) => dispatch => {
    dispatch({
        type: SET_STATISTICS,
        statistics: statistics
    });
}

export const clearWarehouseStatistics = () => dispatch => {
    dispatch({
        type: CLEAR_STATISTICS
    });
}
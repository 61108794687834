import React from 'react';
import { connect } from 'react-redux';
import { getInventory, clearInventory, saveInventory, changeField, clearErrors } from '../actions/inventory.action'
import Inventory from '../components/inventory.component';

class InventoryContainer extends React.Component {
    render() {
        return (
            <div>
                <Inventory {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        inventory: store.inventory.inventory,
        saved: store.inventory.saved,
        isAdmin: store.auth.isAdmin,
        errors: store.inventory.errors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getInventory: (id) => {
            dispatch(getInventory(id));
        },
        clearInventory: () => {
            dispatch(clearInventory());
        },
        saveInventory: () => {
            dispatch(saveInventory());
        },
        changeField: (name, value) => {
            dispatch(changeField(name, value))
        },
        clearErrors: () => {
            dispatch(clearErrors());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryContainer);
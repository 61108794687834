import React from 'react';
import { connect } from 'react-redux';
import { changeField, clearProfile, getProfile, saveProfile } from '../actions/profile.action';
import Profile from '../components/profile.component';

class ProfileContainer extends React.Component {
    render() {
        return (
            <div>
                <Profile {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        profile: store.profile.profile,
        errors: store.profile.errors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProfile: (id) => {
            dispatch(getProfile(id));
        },
        clearProfile: () => {
            dispatch(clearProfile());
        },
        saveProfile: () => {
            dispatch(saveProfile());
        },
        changeField: (value, name) => {
            dispatch(changeField(value, name));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
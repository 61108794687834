import React from 'react';
import { connect } from 'react-redux';

import '../products.css';

import Product from '../components/product.component';

import { getProduct, editProduct, addProduct, clearProduct, changeField, clearErrors } from '../actions/product.actions';


class ProductContainer extends React.Component {
    render() {
        return (
            <div>
                <Product {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        product: store.product
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProduct: (id) => {
            dispatch(getProduct(id));
        },
        addProduct: (onSuccess) => {
            dispatch(addProduct(onSuccess));
        },
        editProduct: (onSuccess) => {
            dispatch(editProduct(onSuccess));
        },
        clearProduct: () => {
            dispatch(clearProduct());
        },
        clearErrors: () => {
            dispatch(clearErrors());
        },
        changeField: (name, value) => {
            dispatch(changeField(name, value));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
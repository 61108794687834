import React from 'react';
import { connect } from 'react-redux';
import { getUser, clearUser, saveUser, changeField, clearErrors } from '../actions/user.action'
import User from '../components/user.component';

class UserContainer extends React.Component {
    render() {
        return (
            <div>
                <User {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        user: store.user.user,
        saved: store.user.saved,
        isAdmin: store.auth.isAdmin,
        errors: store.user.errors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUser: (id) => {
            dispatch(getUser(id));
        },
        clearUser: () => {
            dispatch(clearUser());
        },
        saveUser: () => {
            dispatch(saveUser());
        },
        changeField: (name, value) => {
            dispatch(changeField(name, value))
        },
        clearErrors: () => {
            dispatch(clearErrors());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
import Api from '../../services/api';

import { setCode } from './generator.code.actions'
import { isLoading } from '../../application/actions/application.actions'

const namespace = 'CODES';
export const SET_CODES = `SET_CODES${namespace}`;
export const CLEAR_CODES = `CLEAR_CODES${namespace}`;
export const RESET_FILTERS = `RESET_FILTERS_${namespace}`;
export const SET_FILTERS = `SET_FILTERS_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;

export const clearCodes = () => (dispatch) => {
    dispatch({
        type: CLEAR_CODES
    });
}

export const setCodes = (codes, total) => (dispatch) => {
    dispatch({
        type: SET_CODES,
        codes: codes,
        total: total
    });
}

export const getCodes = () => async (dispatch, getState) => {
    const { list: { limit, offset, filters } } = getState();

    dispatch(isLoading(true));

    let params = {
        "sort[createdAt]": -1,
        "limit": limit,
        "offset": offset
    }

    if (filters.name) {
        params["name"] = filters.name;
    }
    if (filters.eanNr) {
        params["eanNr"] = filters.eanNr;
    }
    if (filters.seriesNr) {
        params["seriesNr"] = filters.seriesNr;
    }

    let res = await Api.get('/generator/generator', params);

    if (res && res.success) {
        dispatch(isLoading(false));

        if (res.documents && res.documents.length === 0) {
            if (filters.eanNr && filters.seriesNr) {
                dispatch(setCode({ eanNr: filters.eanNr, seriesNr: filters.seriesNr }));
            }
            dispatch(setCodes(res.documents));
        } else {
            dispatch(setCodes(res.documents, res.total));
        }

    }
}

export const setFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_FILTERS,
        filter,
        value
    });
}

export const resetFilters = () => dispatch => {
    dispatch({
        type: RESET_FILTERS
    });
}

export const setOffset = (offset) => (dispatch, getState) => {
    dispatch({
        type: SET_OFFSET,
        offset: offset
    });
}
import React from 'react';
import DictionaryForm from "../partials/dictionaryForm.component";
import EnovaDocumentTypesForm from "../partials/enovaDocumentTypesForm.component";
import AutoDocumentsCompletionForm  from "../partials/autoDocumentsCompletionForm.component";

export default class Settings extends React.Component {

    state = {
        name: ""
    }

    getItem = (type, name) => {
        const { getItem, setDirty } = this.props;
        getItem(type);
        setDirty(false);
        this.setState({ name: name });
    }

    renderForm = () => {
        const { item } = this.props;
        if (!item) {
            return null;
        } else {
            switch (item.type) {
                case 1:
                case 2:
                case 3:
                    return <DictionaryForm item={item} addValue={this.addValue} deleteValue={this.deleteValue} setValue={this.setValue} />
                case 4:
                    return <EnovaDocumentTypesForm item={item} addValue={this.addValue} deleteValue={this.deleteValue} setValue={this.setValue} />
                case 5:
                    return <AutoDocumentsCompletionForm item={item} addValue={this.addValue} deleteValue={this.deleteValue} setValue={this.setValue} setSimpleValue={this.setSimpleValue} />
                default: return null;
            }
        }
    }

    addValue = () => {
        const { addValue } = this.props;
        addValue();
    }

    deleteValue = (index) => {
        const { deleteValue } = this.props;
        deleteValue(index);
    }

    setValue = (e, name, index) => {
        let value = e.target.value;
        const { setValue } = this.props;
        setValue(value, name, index);
    }

    setSimpleValue = (value, name, index) => {
        const { setValue } = this.props;
        setValue(value, name, index);
    }

    renderHeader = () => {
        const { name } = this.state;
        const { item } = this.props;
        //Dla których typów ukryć przycisk dodaj

        if (!item) {
            return null;
        }
        let showAdd = [].indexOf(item.type) === -1;

        return (<div className="row">
            <div className="col-6 offset-3">
                <h4 className="mb-5 mt-3">{name}</h4>
            </div>
            {showAdd && <div className="col-3 text-center">
                <button className="btn btn-secondary mt-3 pt-1" onClick={() => this.addValue()}>Dodaj</button>
            </div>}
        </div>
        );
    }

    saveItem = () => {
        const { saveItem } = this.props;
        saveItem();
    }

    render() {
        const { isDirty } = this.props;
        const form = this.renderForm();
        const header = this.renderHeader();

        return (
            <div className="d-flex" style={{ minHeight: '93.4vh' }}>
                <div className="w-25 list-group user-select-none border">
                    <div className="list-group-item list-group-item-action" onClick={() => this.getItem(1, "Typy produktów")}>Typy produktów</div>
                    <div className="list-group-item list-group-item-action" onClick={() => this.getItem(2, "Typy transportu")}>Typy transportu</div>
                    <div className="list-group-item list-group-item-action" onClick={() => this.getItem(3, "Jednostki miar")}>Jednostki miar</div>
                    <div className="list-group-item list-group-item-action" onClick={() => this.getItem(4, "Typy dokumentów")}>Typy dokumentów Enova</div>
                    <div className="list-group-item list-group-item-action" onClick={() => this.getItem(5, "Automatyczne zatwierdzanie dokumentów")}>Automatyczne zatwierdzanie dokumentów</div>
                </div>
                <div className="p-3 text-center container">
                    {header}
                    {form}
                    {isDirty && <button className="btn btn-primary mt-5" onClick={() => this.saveItem()}>Zapisz</button>}
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        const { clearItem, setDirty } = this.props;
        clearItem();
        setDirty(false);
    }
}
import React from 'react';
import { connect } from 'react-redux';
import { getItem, clearItem, setDirty, addValue, deleteValue, setValue, saveItem, setValues } from '../actions/settings.actions'
import Settings from '../components/settings.component';

class SettingsContainer extends React.Component {
    render() {
        return (
            <div>
                <Settings {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        item: store.settings.item,
        isDirty: store.settings.isDirty
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getItem: (type) => {
            dispatch(getItem(type));
        },
        clearItem: () => {
            dispatch(clearItem());
        },
        setDirty: (isDirty) => {
            dispatch(setDirty(isDirty));
        },
        addValue: () => {
            dispatch(addValue());
        },
        deleteValue: (index) => {
            dispatch(deleteValue(index));
        },
        setValue: (value, name, index) => {
            dispatch(setValue(value, name, index));
        },
        saveItem: () => {
            dispatch(saveItem());
        },
        setValues: (values) => {
            dispatch(setValues(values));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
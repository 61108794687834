import FileSaver from "file-saver";
import { toast } from 'react-toastify';
import { isLoading } from '../../application/actions/application.actions';
import Api from '../../services/api';

const namespace = 'REPORT';

export const SET_REPORT = `SET_REPORT_${namespace}`;
export const CLEAR_REPORT = `CLEAR_REPORT_${namespace}`;
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${namespace}`;

export const setReport = (report) => (dispatch) => {
    dispatch({
        type: SET_REPORT,
        report: report
    });
}

export const clearReport = () => (dispatch) => {
    dispatch({
        type: CLEAR_REPORT
    });
}

export const changeField = (name, value) => (dispatch, getState) => {
    const { report: { report } } = getState();

    let newReport = Object.assign({}, report);
    newReport[name] = value;
    dispatch(setReport(newReport));
}

export const generateReport = () => async (dispatch, getState) => {
    try {
        const { report: { report } } = getState();

        dispatch(clearErrors());

        dispatch(isLoading(true));

        let res = await Api.post("/statistic/report", report, true, false, null, null, "text");

        dispatch(isLoading(false));

        if (res && !res.err) {
            var blob = new Blob([res], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            FileSaver.saveAs(blob, "Raport.xlsx");
            toast('Pobrano raport');
        } else {
            toast('Wystąpił błąd');
        }

    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const setErrors = (errors) => (dispatch) => {
    dispatch({
        type: SET_ERRORS,
        errors: errors
    });
}


export const clearErrors = (errors) => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    });
}
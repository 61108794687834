import React from "react";

export default class DictionaryForm extends React.Component {

    setName(e, index) {
        const { setValue } = this.props;
        setValue(e, 'name', index);
        setValue(e, 'value', index);
        setValue(e, 'importType', index);
    }

    render() {
        const { item, setValue, deleteValue } = this.props;
        if (!item) {
            return null;
        }
        let inputs = item.values.map((obj, index) => {
            return (
                <div className="row" key={index}>
                    <div className="col-3">
                        <label>Symbol dokumentu</label>
                        <input type="text" disabled={obj.protected} className="form-control" placeholder="Wpisz symbol" value={obj.name} onChange={(e) => this.setName(e, index)} />
                    </div>
                    <div className="col-3">
                        <label>Importuj jako</label>
                        <input type="text" disabled={obj.protected} className="form-control" placeholder="Wpisz symbol" value={obj.importType} onChange={(e) => this.setValue(e, 'importType', index)} />
                    </div>
                    <div className="col-4">
                        <label>Działanie</label>
                        <select disabled={obj.protected} className="form-control" placeholder="Wybierz akcje" value={obj.action} onChange={(e) => setValue(e, 'action', index)}>
                            <option value="internal">Wewnętrzny</option>
                            <option value="subtract">Rozchodowy</option>
                            <option value="add">Przychodowy</option>
                            <option value="correction">Korekta</option>
                        </select>
                    </div>
                    {!obj.protected && <div className="col-2 pt-1">
                        <button className="btn btn-danger mt-4" onClick={() => { deleteValue(index) }}>Usuń</button>
                    </div>}
                </div>
            );
        });

        return (<div>
            <div className="alert alert-warning">
                Ustawienie definiuje symbole dokumentów z systemu Enova, które będą importowane, realizowane oraz widoczne w filtrach.
            </div>
            {inputs}
        </div>);
    }
}

import produce from "immer";

import {
    SET_ERRORS,
    CLEAR_DOCUMENTS,
    IS_LOADING
} from '../actions/symfoniaImport.actions';



const documentsDefaultState = {
    documents: null,
    isLoading: false
}

const symfoniaImportReducer = (state = documentsDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_ERRORS: {
                draft.documents = action.errors;
                break;
            }
            case CLEAR_DOCUMENTS: {
                draft.documents = null;
                break;
            }
            case IS_LOADING: {
                draft.isLoading = !draft.isLoading;
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default symfoniaImportReducer;
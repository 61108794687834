import { toast } from 'react-toastify';
import { isLoading } from '../../application/actions/application.actions';
import Api from '../../services/api';

const namespace = 'PROFILE';
export const SET_PROFILE = `SET_PROFILE_${namespace}`;
export const CLEAR_PROFILE = `CLEAR_PROFILE_${namespace}`;
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${namespace}`;

export const setProfile = (profile) => (dispatch) => {
    dispatch({
        type: SET_PROFILE,
        profile: profile
    });
}

export const clearProfile = () => (dispatch) => {
    dispatch({
        type: CLEAR_PROFILE
    });
}

export const setErrors = (errors) => (dispatch) => {
    dispatch({
        type: SET_ERRORS,
        errors: errors
    });
}

export const clearErrors = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    });
}

export const getProfile = (id) => async (dispatch) => {
    dispatch(isLoading(true));

    let res = await Api.get(`/application/profile/${id}`);

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setProfile(res.document));
    } else {
        toast('Wystąpił błąd');
    }
}

export const changeField = (value, name) => (dispatch, getState) => {
    const { profile: { profile } } = getState();

    let newProfile = JSON.parse(JSON.stringify(profile));
    newProfile[name] = value;
    dispatch(setProfile(newProfile));
}

export const saveProfile = () => async (dispatch, getState) => {
    const { profile: { profile } } = getState();

    if (profile.newPassword && profile.newPasswordRepeat) {
        if (profile.newPassword !== profile.newPasswordRepeat) {
            toast('Wystąpił błąd');
            dispatch(setErrors({
                newPassword: "Hasła nie są takie same",
                newPasswordRepeat: "Hasła nie są takie same"
            }));
            return;
        }
    }

    dispatch(isLoading(true));
    dispatch(clearErrors());

    let res = await Api.put(`/application/profile/${profile._id}`, profile);

    dispatch(isLoading(false));

    if (res && res.success) {
        toast('Zapisano');
    } else {
        toast('Wystąpił błąd');
        if (res.err) {
            dispatch(setErrors(res.err));
        }
    }
}
